//@ts-nocheck

import { Img } from "@chakra-ui/image";
import { Flex } from "@chakra-ui/layout";
import { useLocation } from "react-router-dom";
import { BottomMenu } from "../BottomMenu";
import { useBreakpoint } from "@chakra-ui/react";

const Footer: React.VFC = () => {
  const location = useLocation();
  const breakpoint = useBreakpoint();

  let menuActive = false;
  if(location.pathname.includes("rfid.tmg.agr.br")) {
    menuActive =
      !location.pathname.includes("/login") && !location.pathname.includes("/forgot") && location.pathname !== "/rfid.tmg.agr.br/";
  } else {
    menuActive =
      location.pathname !== "/login" && location.pathname !== "/forgot" && location.pathname !== "/";
  }

  let signatureActive = false;

  if(breakpoint === "base" || breakpoint === "sm") {
    if(!location.pathname.includes("history") && !location.pathname.includes("realtime")) {
      signatureActive = true;
    } else {
      signatureActive = false;
    }
  } else {
    signatureActive = true;
  }

  return (
    <>
      {
        signatureActive ?
        <Flex
          bottom={0}
          position="relative"
          width="100%"
          height="94px"
          background="#17191A"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          marginBottom={{ base: menuActive ? "80px" : 0, md: 0 }}
        >
          <a href="https://www.clickweb.com.br/?utm_source=painel_tmg&utm_medium=link+copyright&utm_content=link+copyright&utm_campaign=link+copyright" target="_blank" rel="noopener noreferrer">
            <Img src="./clickweb.png" width="50px" marginTop={1} />
          </a>
        </Flex>
        : <div style={{ height: "90px" }} />
      }
      {menuActive ? <BottomMenu /> : <></>}
    </>
  );
};

export { Footer };
