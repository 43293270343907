//@ts-nocheck

import Icon from "@chakra-ui/icon";
import { Flex, Text } from "@chakra-ui/react";
import { useLocation } from "react-router";

const HistoryBox: React.VFC = () => {
  const location = useLocation();
  const color = location.pathname.match("/history") ? "#444444" : "gray.50";
  return (
    <Flex w="100%" height="50px" textAlign="center" direction="column" justifyContent="center" alignItems="center">
      <Icon viewBox="0 0 70 70" color={color} width="18%" boxSize={6}>
        <path
          fill="currentColor"
          d="M548.23,416.52H499.77a8.28,8.28,0,0,0-8.27,8.27v6.45a8.28,8.28,0,0,0,8.27,8.28h48.46a8.28,8.28,0,0,0,8.27-8.28v-6.45A8.28,8.28,0,0,0,548.23,416.52Zm3.27,14.72a3.28,3.28,0,0,1-3.27,3.28H499.77a3.28,3.28,0,0,1-3.27-3.28v-6.45a3.27,3.27,0,0,1,3.27-3.27h48.46a3.27,3.27,0,0,1,3.27,3.27Z"
          transform="translate(-491.5 -416.52)"
        />
        <path
          fill="currentColor"
          d="M548.23,443.06H499.77a8.28,8.28,0,0,0-8.27,8.27v6.46a8.28,8.28,0,0,0,8.27,8.27h48.46a8.28,8.28,0,0,0,8.27-8.27v-6.46A8.28,8.28,0,0,0,548.23,443.06Zm3.27,14.73a3.28,3.28,0,0,1-3.27,3.27H499.77a3.28,3.28,0,0,1-3.27-3.27v-6.46a3.27,3.27,0,0,1,3.27-3.27h48.46a3.27,3.27,0,0,1,3.27,3.27Z"
          transform="translate(-491.5 -416.52)"
        />
        <path
          fill="currentColor"
          d="M515.5,425.52h-12a2.5,2.5,0,0,0,0,5h12a2.5,2.5,0,0,0,0-5Z"
          transform="translate(-491.5 -416.52)"
        />
      </Icon>
      <Text mt="2" fontSize="10" color={color}>
        HISTÓRICO
      </Text>
    </Flex>
  );
};

export { HistoryBox };
