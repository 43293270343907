//@ts-nocheck

import "./styles.module.scss";

import {
	Box,
	FormControl,
	FormErrorMessage,
	FormHelperText,
	FormLabel,
	Input,
	Select,
	InputGroup,
	InputProps,
	SelectProps,
	InputRightElement,
} from "@chakra-ui/react";
import { useState } from "react";
import { GoEye, GoEyeClosed } from "react-icons/go";
import { IMaskInput } from 'react-imask';

type Option = {
	key: number;
	value: string;
};

type Props = InputProps&
	SelectProps&{
	name: string;
	error?: any;
	label: string;
	hintMessage?: string;
	marginTop?: string;
	mask?: any;
	options?: Option[];
};

const TMGInput: React.FC<Props> = ({name, label, error, hintMessage, marginTop = "0", mask, options, ...props}) => {
	const [show, setShow] = useState(false);
	const handleClick     = () => setShow(!show);

	return (
		<FormControl id={name} marginTop={marginTop}>
			<FormLabel fontSize={14} textTransform="uppercase" paddingLeft="4">
				{label}
			</FormLabel>

			<InputGroup>
				{props.type === "password" && (
					<>
						<Input
							{...props}
							placeholder={label}
							background="gray.200"
							colorScheme="gray"
							borderColor="transparent"
							color="#ffffff"
							py="6"
							type={show ? "text" : "password"}
							fontSize={{base: '16px', md: '18px'}}
						/>
						<InputRightElement width="4.5rem">
							<Box onClick={handleClick} role="button" cursor="pointer" pt="3">
								{show ? <GoEye size={23} /> : <GoEyeClosed size={23} />}
							</Box>
						</InputRightElement>
					</>
				)}

				{(props.type === "text" ||
					props.type === "number" ||
					props.type === "email" ||
					props.type === "tel" ||
					props.type === "date") && (
					<>
						{!!mask ? (
							<Input
								{...props}
								placeholder={props.placeholder ? props.placeholder : label}
								background="gray.200"
								colorScheme="gray"
								borderColor="transparent"
								color="#ffffff"
								py="6"
								fontSize={{base: '16px', md: '18px'}}
								as={IMaskInput}
								mask={mask}
								unmask={false} // true|false|'typed'
							/>
						) : (
							<Input
								{...props}
								placeholder={props.placeholder ? props.placeholder : label}
								background="gray.200"
								colorScheme="gray"
								borderColor="transparent"
								color="#ffffff"
								py="6"
								fontSize={{base: '16px', md: '18px'}}
							/>
						)}
					</>
				)}

				{props.type === "select" && (
					<Select
						{...props}
						size="lg"
						placeholder={label}
						background="gray.200"
						colorScheme="gray"
						borderColor="transparent"
						height="52px"
						py="6"
						my="-6"
						fontSize={{base: '16px', md: '18px'}}
					>
						{options?.map((opt) => (
							<option key={opt.key} value={opt.key}>
								{opt.value}
							</option>
						))}
					</Select>
				)}
			</InputGroup>

			{!error && hintMessage && <FormHelperText>{hintMessage}</FormHelperText>}
			{error && <FormErrorMessage>{error}</FormErrorMessage>}
		</FormControl>
	);
};

export { TMGInput };
