//@ts-nocheck

import { Box, VStack } from "@chakra-ui/layout";
import { NavLink } from "react-router-dom";
import { HistoryBox } from "./Boxes/history";
import { MyDataBox } from "./Boxes/mydata";
import { RealTimeBox } from "./Boxes/realtime";

const LeftMenu: React.VFC = () => {
	return (
		<VStack
			w="140px"
			minH="100vh"
			spacing="70px"
			background="#141316"
			position="fixed"
			top="0"
			left="0"
			zIndex={2}
			display={{
				base: "none",
				md  : "flex",
			}}>
			<Box mt="12">
				<NavLink to="/">
					<HistoryBox />
				</NavLink>
				<NavLink to="/realtime">
					<RealTimeBox />
				</NavLink>
				<NavLink to="/mydata">
					<MyDataBox />
				</NavLink>
			</Box>
		</VStack>
	);
};

export { LeftMenu };
