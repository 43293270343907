//@ts-nocheck

import { Icon } from "@chakra-ui/icons";
import { Grid, Box, GridItem, Flex, Text } from "@chakra-ui/react";
import { Dispatch, SetStateAction } from "react";

import { Filter, FormFilter } from "../../FormFilter";

type Props = {
	filter: Filter;
	setFilter: Dispatch<SetStateAction<Filter>>;
	setIsApplied: Dispatch<SetStateAction<boolean>>;
	onBack: () => void;
};

const MobileFilter: React.VFC<Props> = ({ onBack, setIsApplied, filter, setFilter, ...props }) => {
	const enhancedSubmit = () => {
		setIsApplied(true);
		onBack();
	};

	return (
		<Box
			width="100%"
			background="gray.300"
			height="100vh"
			zIndex={999}
			position="fixed"
		>
			<Grid
				templateColumns="repeat(12, 1fr)"
				p="5"
				width="100%"
				background="custom.500"
			>
				<GridItem colSpan={1} display="flex" alignItems="center">
					<Flex onClick={onBack}>
						<Icon viewBox="0 0 51.5 43" width="24px" height="20px" boxSize={6}>
							<path
								fill="#fff"
								d="M49,19H8.6L23.3,4.3c1-1,1-2.6,0-3.5c-1-1-2.6-1-3.5,0L1,19.5c-0.6,0.5-1,1.2-1,2c0,0,0,0,0,0
c0,0.6,0.2,1.3,0.7,1.8l19,19c0.5,0.5,1.1,0.7,1.8,0.7s1.3-0.2,1.8-0.7c1-1,1-2.6,0-3.5L8.5,24H49c1.4,0,2.5-1.1,2.5-2.5
S50.4,19,49,19z"
							/>
						</Icon>
					</Flex>
				</GridItem>
				<GridItem
					colSpan={4}
					colStart={5}
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<Text
						color="white"
						fontWeight="bold"
						fontSize="22px"
						textAlign="center"
						d="flex"
					>
						FILTROS
					</Text>
				</GridItem>
			</Grid>
			<Flex
				paddingLeft="20px"
				paddingRight="20px"
				wrap="wrap"
				justifyContent="space-between"
				mt="30px"
			>
				<FormFilter
					{...props}
					filter={filter}
					setFilter={setFilter}
					onSubmit={enhancedSubmit}
				/>
			</Flex>
		</Box>
	);
};

export { MobileFilter };
