//@ts-nocheck

import { Box, Grid, GridItem, Icon, Flex, Text } from "@chakra-ui/react";
import { useDispatch } from "react-redux";

import { authActions } from "../../redux/actions";

const BasicHeader: React.VFC<{title: string; withLogout: boolean}> = ({title, withLogout}) => {
	const dispatch = useDispatch();

	function logout() {
		dispatch(authActions.logout());
	}

	return (
		<>
			<Box
				height={{base: "100px", md: "130px"}}
				display="grid"
				px={{base: '20px', md: '60px'}}
				py="10"
				width="100%"
				background="gray.300"
			>
				<Grid templateColumns="repeat(12, 1fr)">
					<GridItem
						d="flex"
						colSpan={{base: withLogout ? 8 : 12, md: 6}}
						alignItems="center"
						fontWeight="bold"
						color="white"
						fontSize={{base: '22px', md: '32px'}}
						justifyContent={{
							base: withLogout ? "flex-end" : "center",
							md  : "flex-start",
						}}
					>
						{title}
					</GridItem>
					{withLogout && (
						<GridItem
							d="flex"
							colSpan={{base: 4, md: 6}}
							alignItems="center"
							color="custom.50"
							justifyContent={{base: "flex-end", md: "flex-end"}}
						>
							<Flex
								cursor="pointer"
								_hover={{color: "white"}}
								onClick={logout}
							>
								<Icon
									viewBox="0 0 27.8 19"
									color="custom.300"
									width="18%"
									boxSize={6}
								>
									<path
										fill="currentColor"
										d="M27.5,8.8l-8-8c-0.4-0.4-1-0.4-1.4,0l-1.4,1.4c-0.4,0.4-0.4,1,0,1.4l3.9,3.9H9c-0.6,0-1,0.5-1,1v2
		c0,0.6,0.5,1,1,1h11.6l-3.9,3.9c-0.4,0.4-0.4,1,0,1.4l1.4,1.4c0.4,0.4,1,0.4,1.4,0l8-8C27.9,9.8,27.9,9.2,27.5,8.8z"
									/>
									<path
										fill="currentColor"
										d="M11.4,15H4V4h7.4c0.6,0,1-0.5,1-1V1c0-0.6-0.5-1-1-1H1C0.5,0,0,0.5,0,1v17c0,0.6,0.5,1,1,1h10.4
		c0.6,0,1-0.5,1-1v-2C12.4,15.5,11.9,15,11.4,15z"
									/>
								</Icon>
								<Text fontSize={{base: '16px', md: '18px'}} ml="2">SAIR</Text>
							</Flex>
						</GridItem>
					)}
				</Grid>
			</Box>
		</>
	);
};

export { BasicHeader };
