import { osName, browserName } from "./../helpers/client";

// -----------------------------------------------------------------------------
// General
// -----------------------------------------------------------------------------
export const IS_DEBUG    = process.env.NODE_ENV === 'development';
export const CLIENT_DATA = {
	os_name     : osName(),
	browser_name: browserName(),
};
export const ROUTE_PATH  = process.env.PUBLIC_URL;

// -----------------------------------------------------------------------------
// API
// -----------------------------------------------------------------------------
export const API_URL = IS_DEBUG ? "http://api.rfid.tmg.local/api/v1/customer/" : "https://api.rfid.tmg.agr.br/api/v1/customer/";

// -----------------------------------------------------------------------------
// Socket
// -----------------------------------------------------------------------------
export const WS_URL         = IS_DEBUG ? "api.rfid.tmg.local" : "api.rfid.tmg.agr.br";
export const WS_AUTH_URL    = IS_DEBUG ? "https://api.rfid.tmg.local/broadcasting/auth" : "https://api.rfid.tmg.agr.br/broadcasting/auth";
export const WS_PORT        = 6001;
export const PUSHER_APP_KEY = 'NCVsEDMCtxuhSlSoquFTbO72n8IjCwRT';

// -----------------------------------------------------------------------------
// Errors
// -----------------------------------------------------------------------------
export const API_ERRO_TYPE_VALIDATION   = "validation";
export const API_ERRO_TYPE_API          = "api";
export const API_ERRO_TYPE_SERVER       = "server";
export const API_ERRO_TYPE_CONNECTION   = "connection";
export const API_ERRO_TYPE_OTHER        = "other";
export const API_ERRO_TYPE_ACCESS_TOKEN = "access_token";
export const API_ERRO_TYPE_CANCEL       = "cancel";
