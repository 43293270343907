//@ts-nocheck

import { REHYDRATE } from "redux-persist";
import { authConstants } from "./../constants";

const reducerKey = "auth";

const defaultState = {
	isAuthenticated  : false,
	isLoadingUserData: false,
	accessToken      : "",
	userData         : {
		uuid          : "",
		name          : "",
		email         : "",
		avatar        : null,
		phone         : "",
		custom_data   : null,
		unity_id      : '',
		unity_name    : '',
		unity_location: '',
	},
};

export default function reducer(state = defaultState, action) {
	switch( action.type )
	{
		case REHYDRATE:
			let persistUpdate = {};

			if( action.payload && action.payload[reducerKey] )
			{
				const persistCache = action.payload[reducerKey];

				persistUpdate = {
					isAuthenticated: persistCache.isAuthenticated,
					accessToken    : persistCache.accessToken,
				};

				if( persistCache.userData )
				{
					persistUpdate.userData = {
						uuid          : persistCache.userData.uuid ?? defaultState.userData.uuid,
						name          : persistCache.userData.name ?? defaultState.userData.name,
						email         : persistCache.userData.email ?? defaultState.userData.email,
						avatar        : persistCache.userData.avatar ?? defaultState.userData.avatar,
						phone         : persistCache.userData.phone ?? defaultState.userData.phone,
						custom_data   : persistCache.userData.custom_data ?? defaultState.userData.custom_data,
						unity_id      : persistCache.userData.unity_id ?? defaultState.userData.unity_id,
						unity_name    : persistCache.userData.unity_name ?? defaultState.userData.unity_name,
						unity_location: persistCache.userData.unity_location ?? defaultState.userData.unity_location,
					}
				}
			}

			return Object.assign({}, state, persistUpdate);

		case authConstants.LOGOUT:
			return Object.assign({}, state, defaultState);

		case authConstants.LOGIN:
			return Object.assign({}, state, {
				isAuthenticated: true,
				accessToken    : `Bearer ${action.data.accessToken}`,
				userData       : {
					...state.userData,
					uuid          : action.data.uuid,
					name          : action.data.name,
					email         : action.data.email,
					avatar        : action.data.avatar,
					phone         : action.data.phone,
					custom_data   : action.data.custom_data,
					unity_id      : action.data.unity?.uuid ?? defaultState.userData.unity_id,
					unity_name    : action.data.unity?.name ?? defaultState.userData.unity_name,
					unity_location: action.data.unity?.location ?? defaultState.userData.unity_location,
				}
			});

		case authConstants.EDIT_USER_DATA:
			return Object.assign({}, state, {
				userData: {
					...state.userData,
					name          : action.data.name,
					email         : action.data.email,
					avatar        : action.data.avatar,
					phone         : action.data.phone,
					custom_data   : action.data.custom_data,
					unity_id      : action.data.unity?.uuid ?? defaultState.userData.unity_id,
					unity_name    : action.data.unity?.name ?? defaultState.userData.unity_name,
					unity_location: action.data.unity?.location ?? defaultState.userData.unity_location,
				},
			});

		case authConstants.USERDATA_REQUEST:
			return Object.assign({}, state, {
				isLoadingUserData: true,
			});

		case authConstants.USERDATA_SUCCESS:
			return Object.assign({}, state, {
				isLoadingUserData: false,
				userData         : {
					...state.userData,
					name          : action.data.name,
					email         : action.data.email,
					avatar        : action.data.avatar,
					phone         : action.data.phone,
					custom_data   : action.data.custom_data,
					unity_id      : action.data.unity?.uuid ?? defaultState.userData.unity_id,
					unity_name    : action.data.unity?.name ?? defaultState.userData.unity_name,
					unity_location: action.data.unity?.location ?? defaultState.userData.unity_location,
				}
			});

		case authConstants.USERDATA_ERROR:
			return Object.assign({}, state, {
				isLoadingUserData: false,
			});

		case authConstants.EDIT_USER_AVATAR:
			return Object.assign({}, state, {
				userData: {
					...state.userData,
					avatar: action.data.avatar,
				},
			});

		default:
			return state;
	}
}
