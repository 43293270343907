//@ts-nocheck

import { Box, FlexProps, Text } from "@chakra-ui/react";

type Props = FlexProps & {
  label: string;
  value: string;
  fontSizeLabel?: string;
  fontSizeSubtitle?: string;
};

const LabelValue: React.VFC<Props> = ({ label, value, fontSizeLabel, fontSizeSubtitle, ...props }) => {
  return (
    <Box {...props} width="100%">
      <Text mb="1" fontSize={fontSizeLabel ? { base: '12px', md: fontSizeLabel } : { base: '12px', md: '14px'}} color="#676870" textTransform="uppercase">{label}</Text>
      <Text fontSize={fontSizeSubtitle ? { base: '16px', md: fontSizeSubtitle } : { base: '16px', md: '18px'}} color="#fff">
        {value}
      </Text>
    </Box>
  );
};

export { LabelValue };
