//@ts-nocheck

import {
	Alert,
	AlertIcon,
	AlertDescription,
	Grid,
	GridItem,
	Button,
	Box,
	Text,
} from "@chakra-ui/react";
import { Footer } from "../../../components/Footer";
import { ReturnHeader } from "../../../components/ReturnHeader";
import { LeftMenu } from "../../../components/LeftMenu";
import { TMGInput } from "../../../components/Input";
import { useHistory, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { toast } from 'react-toastify';

import { authService } from "../../../redux/services";

const MyDataPassword: React.VFC = () => {
	const [currentPass, setCurrentPass]                   = useState("");
	const [pass, setPass]                                 = useState("");
	const [newPass, setNewPass]                           = useState("");
	const [notEquals, setNotEquals]                       = useState(false);
	const [error, setError]                               = useState("");
	const [showCurrentPassError, setShowCurrentPassError] = useState(false);
	const [showPassError, setShowPassError]               = useState(false);
	const [showNewPassError, setShowNewPassError]         = useState(false);
	const [isLoading, setIsLoading]                       = useState(false);

	const history  = useHistory();
	const location = useLocation();

	function clearStates() {
		setError("");
		setCurrentPass("");
		setPass("");
		setNewPass("");
		setNotEquals(false);
		setShowPassError(false);
		setShowNewPassError(false);
		setIsLoading(false);
	}

	useEffect(() => {
		clearStates();
	}, [location]);

	useEffect(() => {
		if( pass === newPass )
		{
			setNotEquals(false);
		}
		else
		{
			setNotEquals(true);
		}
	}, [pass, newPass]);

	useEffect(() => {
		setShowPassError(false);
		setShowNewPassError(false);
	}, [notEquals]);

	function validateForm() {
		if( !currentPass )
		{
			setShowCurrentPassError(true);
		}
		else
		{
			setShowCurrentPassError(false);
		}

		if( !pass )
		{
			setShowPassError(true);
		}
		else
		{
			setShowPassError(false);
		}

		if( !newPass )
		{
			setShowNewPassError(true);
		}
		else
		{
			setShowNewPassError(false);
		}

		if( currentPass && pass && newPass && !notEquals )
		{
			setIsLoading(true);

			const data = {
				password                 : currentPass,
				password_new             : pass,
				password_new_confirmation: newPass,
			};

			authService.changePassword(data)
			.then((response) => {
				toast.success('Senha alterada com sucesso!', {
					theme    : "dark",
					autoClose: 4000
				});

				history.push("/mydata");
				setIsLoading(false);
			})
			.catch((data) => {
				setIsLoading(false);
				setError(String(data));
			});
		}
	}

	return (
		<>
			<LeftMenu />
			<Grid templateColumns="repeat(12, 1fr)">
				<GridItem colSpan={12} marginLeft={{base: 0, md: "140px"}}>
					<ReturnHeader title="ALTERAR SENHA" subtitle="" to="/mydata" />
					<Box
						px={{base: '20px', md: '60px'}}
						pt="8"
						pb="12"
						width="100%"
						minH={{
							base: "calc(100vh - 325px)",
							md  : "calc(100vh - 208px)",
						}}
						bgGradient="linear(#18181A, gray.300)"
					>
						<Grid templateColumns="repeat(12, 1fr)">
							<GridItem colSpan={{base: 12, md: 6}}>
								{!!error && <Alert status='error' mt={5}>
									<AlertIcon />
									<AlertDescription>{error}</AlertDescription>
								</Alert>}
								<TMGInput
									label="Senha atual"
									name="currentpass"
									type="password"
									marginTop="3"
									onChange={(e) => setCurrentPass(e.target.value)}
									onFocus={() => setShowCurrentPassError(false)}
									css={showCurrentPassError && !pass && `
										border-color: #ff4d4d;
										color: #ff4d4d;
										&::placeholder {
										  color: #ff4d4d;
										}
									  `}
								/>
								<Text display={showPassError && !pass ? 'block' : 'none'} textAlign="left" color="#ff4d4d" mt="2" width="100%">Informe a senha atual</Text>

								<TMGInput
									label="Nova senha"
									name="newpass"
									type="password"
									marginTop="3"
									onChange={(e) => setPass(e.target.value)}
									onFocus={() => setShowPassError(false)}
									css={showPassError && !pass && `
										border-color: #ff4d4d;
										color: #ff4d4d;
										&::placeholder {
										  color: #ff4d4d;
										}
									  `}
								/>
								<Text display={showPassError && !pass ? 'block' : 'none'} textAlign="left" color="#ff4d4d" mt="2" width="100%">Informe a nova senha</Text>

								<TMGInput
									label="Confirmar senha"
									name="newpass"
									type="password"
									marginTop="3"
									onChange={(e) => setNewPass(e.target.value)}
									onFocus={() => setShowNewPassError(false)}
									css={showNewPassError && !newPass && `
										border-color: #ff4d4d;
										color: #ff4d4d;
										&::placeholder {
										  color: #ff4d4d;
										}
									  `}
								/>
								<Text display={showNewPassError && !newPass ? 'block' : 'none'} textAlign="left" color="#ff4d4d" mt="2" width="100%">Confirme a nova senha</Text>
								<Text display={notEquals && !showPassError && !showNewPassError ? 'block' : 'none'} textAlign="left" color="#ff4d4d" mt="2" width="100%">As senhas devem ser iguais</Text>
								<Button
									isLoading={isLoading}
									disabled={notEquals}
									colorScheme="blue"
									size="xlg"
									bg="blue.200"
									width="100%"
									mt="10"
									py="6"
									fontWeight="regular"
									_hover={{textDecoration: "none", bg: '#266987'}}
									onClick={validateForm}
								>
									SALVAR
								</Button>
							</GridItem>
						</Grid>
					</Box>
					<Footer />
				</GridItem>
			</Grid>
		</>
	);
};

export { MyDataPassword };
