//@ts-nocheck

import { Button } from "@chakra-ui/button";
import { Checkbox } from "@chakra-ui/checkbox";
import { Flex } from "@chakra-ui/layout";
import moment from "moment";
import { Dispatch, SetStateAction, useState, useEffect } from "react";
import { TMGInput } from "../Input";
import { Input, FormControl, FormLabel, InputGroup } from "@chakra-ui/react";

export type Filter = {
	start: string;
	end: string;
	bag: any;
	in: boolean;
	out: boolean;
};

type Props = {
	filter: Filter;
	setFilter: Dispatch<SetStateAction<Filter>>;
	onSubmit: () => void;
};

const FormFilter: React.VFC<Props> = ({setFilter, onSubmit}) => {
	const [canApply, setCanApply]   = useState(false);
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate]     = useState("");
	const [bag, setBag]             = useState("");
	const [entrada, setEntrada]     = useState(false);
	const [saida, setSaida]         = useState(false);

	// const [startDateHover, setStartDateHover] = useState(false);
	// const [endDateHover, setEndDateHover] = useState(false);

	// const breakpoint = useBreakpoint();

	const handleChange = (e: any, field: string) => {
		if( field === "start" )
		{
			setStartDate(e.target.value);
		}

		if( field === "end" )
		{
			setEndDate(e.target.value);
		}

		if( field === "bag" )
		{
			setBag(e.target.value);
		}

		if( field === "in" )
		{
			setEntrada(e.target.checked);
		}

		if( field === "out" )
		{
			setSaida(e.target.checked);
		}
	};

	const handleSubmit = () => {
		setFilter({
			start: startDate,
			end  : endDate,
			bag  : bag,
			in   : entrada,
			out  : saida
		});
		onSubmit();
	}

	useEffect(() => {
		verifyCanApply();
		// eslint-disable-next-line
	}, [startDate, endDate, bag, entrada, saida]);

	function verifyCanApply() {
		if( startDate && endDate )
		{
			if( new Date(startDate).getTime() > new Date().getTime() )
			{
				setCanApply(false);
			}
			else if( new Date(endDate).getTime() > new Date().getTime() )
			{
				setCanApply(false);
			}
			else if( new Date(startDate).getTime() > new Date(endDate).getTime() )
			{
				setCanApply(false);
			}
			else
			{
				setCanApply(true);
			}
		}
		else if( bag || entrada || saida )
		{
			setCanApply(true);
		}
		else
		{
			setCanApply(false);
		}
	}

	// function changeDateInput(type: string, event: string) {
	// 	if (breakpoint === "base" || breakpoint === "sm") {
	// 		if(event === "hover" && type === "start") {
	// 			if(!startDateHover) {
	// 				setStartDateHover(true);
	// 			}
	// 		} else if(event === "hover" && type === "end") {
	// 			if(!endDateHover) {
	// 				setEndDateHover(true);
	// 			}
	// 		}
	// 	}

	// 	if(event === "click" && type === "start") {
	// 		if(!startDateHover) {
	// 			setStartDateHover(true);
	// 		}
	// 	} else if(event === "click" && type === "end") {
	// 		if(!endDateHover) {
	// 			setEndDateHover(true);
	// 		}
	// 	}
	// }

	return (
		<>
			<Flex gridRowGap="15px" gridColumnGap="20px" wrap="wrap" width={{base: '100%', md: 'unset'}}>
				<Flex
					maxWidth="100%"
					width={{base: 'calc(50% - 10px)', md: '200px'}}
					marginRight={{base: "0", md: "20px"}}
				>
					<FormControl>
						<FormLabel fontSize={14} textTransform="uppercase" paddingLeft="4">
							DE
						</FormLabel>

						<InputGroup position="relative">
							<Input
								css={`
									background-image:url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23fff" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
									background-size:16px 15px;
									background-position:right 15px center;
									background-repeat:no-repeat;
								`}
								placeholder="Inserir data"
								background="gray.200"
								colorScheme="gray"
								borderColor="transparent"
								color="#ffffff"
								readOnly
								value={startDate ? moment(startDate).format('DD/MM/YYYY') : ''}
								py="6"
								fontSize={{base: '16px', md: '18px'}}
								_groupHover={{backgroundColor: "#3e3e41"}}
								_groupFocus={{border: "2px solid #676870", backgroundColor: "gray.200"}}
							/>
							<Input
								className="start-input"
								type="date"
								width="100%"
								height="100%"
								p="0"
								position="absolute"
								top="0"
								left="0"
								opacity="0"
								max={moment(endDate).format('YYYY-MM-DD')}
								onChange={(e) => handleChange(e, "start")}
								role="group"
							/>
						</InputGroup>
					</FormControl>
					{/* <TMGInput
						label="DE"
						placeholder="Inserir data"
						name="start"
						type={startDateHover ? 'date' : 'text'}
						onClick={() => changeDateInput("start", "click")}
						onMouseOver={() => changeDateInput("start", "hover")}
						max={moment(endDate).format('YYYY-MM-DD')}
						onChange={(e) => handleChange(e, "start")}
					/> */}
				</Flex>
				<Flex
					justifyContent={{base: 'flex-end', md: 'flex-start'}}
					maxWidth="100%"
					width={{base: 'calc(50% - 10px)', md: '200px'}}
					marginRight={{base: "0", md: "20px", lg: "90px"}}
				>
					<FormControl>
						<FormLabel fontSize={14} textTransform="uppercase" paddingLeft="4">
							ATÉ
						</FormLabel>

						<InputGroup position="relative">
							<Input
								css={`
									background-image:url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23fff" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
									background-size:16px 15px;
									background-position:right 15px center;
									background-repeat:no-repeat;
								`}
								placeholder="Inserir data"
								background="gray.200"
								colorScheme="gray"
								borderColor="transparent"
								color="#ffffff"
								readOnly
								value={endDate ? moment(endDate).format('DD/MM/YYYY') : ''}
								py="6"
								fontSize={{base: '16px', md: '18px'}}
								_groupHover={{backgroundColor: "#3e3e41"}}
								_groupFocus={{border: "2px solid #676870", backgroundColor: "gray.200"}}
							/>
							<Input
								className="start-input"
								type="date"
								width="100%"
								height="100%"
								p="0"
								position="absolute"
								top="0"
								left="0"
								opacity="0"
								min={moment(startDate).format('YYYY-MM-DD')}
								max={moment().format('YYYY-MM-DD')}
								onChange={(e) => handleChange(e, "end")}
								role="group"
							/>
						</InputGroup>
					</FormControl>
					{/* <TMGInput
						label="ATÉ"
						placeholder="Inserir data"
						name="end"
						type={endDateHover ? 'date' : 'text'}
						onClick={() => changeDateInput("end", "click")}
						onMouseOver={() => changeDateInput("end", "hover")}
						min={moment(startDate).format('YYYY-MM-DD')}
						max={moment().format('YYYY-MM-DD')}
						onChange={(e) => handleChange(e, "end")}
					/> */}
				</Flex>
				<Flex
					maxWidth="100%"
					width={{base: '100%', md: '210px'}}
					marginRight={{base: "0", md: "20px", lg: "90px"}}
				>
					<TMGInput
						label="Bag"
						name="bag"
						type="text"
						onChange={(e) => handleChange(e, "bag")}
					/>
				</Flex>

				<Flex width={{base: '100%', md: 'unset'}} wrap="nowrap">
					<Flex width={{base: '50%', md: 'unset'}} marginRight={{base: '0', md: '40px'}}>
						<Checkbox
							css={`
								> span:first-of-type {
									box-shadow:unset;
								}
							`}
							colorScheme="blue"
							mt={{base: '20px', md: '7'}}
							size="lg"
							borderColor="gray.200"
							onChange={(e) => handleChange(e, "in")}
						>
							Entrada
						</Checkbox>
					</Flex>
					<Flex width={{base: '50%', md: 'unset'}}>
						<Checkbox
							css={`
								> span:first-of-type {
									box-shadow:unset;
								}
							`}
							colorScheme="blue"
							mt={{base: '20px', md: '7'}}
							size="lg"
							borderColor="gray.200"
							onChange={(e) => handleChange(e, "out")}
						>
							Saída
						</Checkbox>
					</Flex>
				</Flex>
			</Flex>
			<Flex width={{base: '100%', md: 'unset'}}>
				<Button
					maxWidth="100%"
					width={{base: '100%', md: '210px'}}
					variant="solid"
					bg="blue.200"
					colorScheme="blue"
					mt="7"
					py="6"
					height="52px"
					textTransform="uppercase"
					fontWeight="regular"
					disabled={!canApply}
					_hover={{textDecoration: "none", bg: '#266987'}}
					onClick={handleSubmit}
				>
					Aplicar
				</Button>
			</Flex>
		</>
	);
};

export { FormFilter };
