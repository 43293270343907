//@ts-nocheck

import { Button } from "@chakra-ui/button";
import { Box } from "@chakra-ui/layout";
import { Tooltip } from "@chakra-ui/react";
import { Dispatch, SetStateAction } from "react";
import { FaFilter } from "react-icons/fa";

type Props = {
  search: string;
  isSearch: boolean;
  isApplied: boolean;
  setIsSearch: Dispatch<SetStateAction<boolean>>;
  setIsApplied: Dispatch<SetStateAction<boolean>>;
};

const SearchWithFilter: React.VFC<Props> = ({
  search,
  isSearch,
  isApplied,
  setIsSearch,
  setIsApplied,
}) => {
  // const breakpoint = useBreakpoint();
  return (
    <>
      {/* {!search ? ( */}
        <Tooltip label="Filtros" placement="top">
          <Button
            colorScheme="black"
            variant="ghost"
            minWidth="52px"
            py="6"
            border="2px"
            borderColor={isApplied || isSearch ? "white" : "gray.200"}
            borderRadius={8}
            color={isApplied || isSearch ? 'white' : 'unset'}
            _hover={{
              background: "transparent",
              borderColor: "white",
              color: "white",
            }}
            css={`
              &:disabled {
                opacity: 1;
              }
            `}
            onClick={() => {
              setIsSearch(!isSearch);
              // setIsApplied(false);
            }}
          >
            <FaFilter size={18} />
            {isApplied && (
              <Box
                width="6px"
                height="6px"
                borderRadius="100"
                position="absolute"
                right="8px"
                top="8px"
                bg="blue.200"
              />
            )}
          </Button>
        </Tooltip>
      {/* ) : (
        <Menu>
          <MenuButton
            variant="ghost"
            border="2px"
            borderColor="gray.200"
            borderRadius={99}
            p="2"
            background="gray.200"
            _hover={{ background: "transparent" }}
          >
            <HiOutlineDotsVertical color="#ffffff" size={26} />
          </MenuButton>
          <MenuList background="gray.200" p={{ base: 0, md: "4" }} border="0">
            <MenuItem
              color="#fff"
              background="gray.200"
              _hover={{ background: "transparent" }}
              _focus={{ background: "transparent" }}
            >
              NOVO INVENTÁRIO
            </MenuItem>
            <MenuItem
              color="#fff"
              background="gray.200"
              _hover={{ background: "transparent" }}
              _focus={{ background: "transparent" }}
            >
              ADICIONAR A UM INVENTÁRIO JÁ EXISTENTE
            </MenuItem>
          </MenuList>
        </Menu>
      )} */}
    </>
  );
};

export { SearchWithFilter };
