//@ts-nocheck

import { Box } from "@chakra-ui/layout";

const TMGContainer: React.VFC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Box
      px={{ base: '20px', md: '60px' }}
      pt="8"
      pb="12"
      width="100%"
      minH={{
        base: "calc(100vh - 477px)",
        md: "calc(100vh - 224px)",
      }}
      bgGradient="linear(#18181A, gray.300)"
    >
      {children}
    </Box>
  );
};

export { TMGContainer };
