// 1. Import `extendTheme`
import { extendTheme } from "@chakra-ui/react";
// 2. Call `extendTheme` and pass your custom values
export const theme = extendTheme({
	fonts: {
		body: "Poppins, sans-serif",
		heading: "Poppins, serif",
		mono: "Poppins, monospace",
	},
	colors: {
		transparent: "transparent",
		black: "#000",
		white: "#fff",
		blue: {
			200: "#0d587a",
			500: "#0BA5B5",
		},
		gray: {
			50: "#9799a3",
			200: "#29282D",
			300: "#1C1E1F",
			400: "#9799a3",
			900: "#141316",
		},
		custom: {
			50: "#9799a3",
			200: "#67586f",
			300: "#3b3c40",
			400: "#2c2c30",
			500: "#28282c",
			600: "#1c1d1e",
			700: "#141416",
		},
	},
	styles: {
		global: {
			body: {
				bg: "#1C1E1F",
				color: "#515354",
			},
			a: {
				_focus: {
					boxShadow: "none",
				},
			},
			option: {
				color: "gray.200",
			},
			button: {
				_focus: {
					boxShadow: "none!important",
				},
			},
		},
	},
	components: {
		Button: {
			baseStyle: {
				fontSize: { base: "16px", md: "18px" },
				maxHeight: { base: "52px", md: "70px" },
				height: "100%",
				_focus: {
					boxShadow: "none",
				},
			},
		},
		Input: {
			baseStyle: {
				field: {
					border: "2px solid #29282D",
					fontSize: { base: "16px", md: "18px" },
					maxHeight: "52px",
					_placeholder: {
						color: "white",
					},
					_hover: {
						bg: "#3e3e41",
						cursor: "pointer",
					},
					_focus: {
						border: "2px solid #676870",
						bg: "gray.200",
					},
				},
			},
			variants: {},
			defaultProps: {
				variant: null,
			},
		},
		Select: {
			baseStyle: {
				field: {
					color: "white",
					border: "2px solid #29282D",
					fontSize: "18px",
					_placeholder: {
						color: "white",
					},
					_hover: {
						bg: "#3e3e41",
						cursor: "pointer",
					},
					_focus: {
						border: "2px solid #676870",
						bg: "gray.200",
					},
				},
			},
			variants: {},
			defaultProps: {
				variant: null,
			},
		},
		Link: {
			baseStyle: {
				_focus: {
					boxShadow: "none",
				},
			},
		},
		Tooltip: {
			baseStyle: {
				borderRadius: "10px",
				bg: "#28282c",
				padding: "3px 15px",
			},
		},
		Checkbox: {
			baseStyle: {
				label: {
					marginLeft: "20px",
					color: "#676870",
				},
				control: {
					bg: "#28282c",
					borderRadius: "5px",
					_hover: {
						bg: "#3e3e42",
						borderColor: "#3e3e42",
					},
					_checked: {
						bg: "#00a7b3",
						_hover: {
							bg: "#00a7b3",
							borderColor: "#00a7b3",
						},
					},
				},
			},
		},
	},
});
