//@ts-nocheck

import {
	Alert,
	AlertIcon,
	AlertDescription,
	Grid,
	GridItem,
	Flex,
	Icon,
	Image,
	Button,
	Circle,
	Input,
	InputGroup,
	FormLabel,
	Box,
	Spinner,
	Tooltip,
	Text,
} from "@chakra-ui/react";
import { Footer } from "../../../components/Footer";
import { ReturnHeader } from "../../../components/ReturnHeader";
import { LeftMenu } from "../../../components/LeftMenu";
import { TMGInput } from "../../../components/Input";
import { useHistory, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';

import { authActions } from "../../../redux/actions";

import { authService } from "../../../redux/services";

const EditImageIcon = () => (
	<Icon
		viewBox="0 0 32 26.3"
		width="22px"
		height="19px"
		boxSize={{base: 4, md: 5}}
		color="custom.300"
	>
		<path
			fill="currentColor"
			d="M30,4.3h-6V2c0-1.1-0.9-2-2-2H10C8.9,0,8,0.9,8,2v2.3H2c-1.1,0-2,0.9-2,2v18c0,1.1,0.9,2,2,2h28
c1.1,0,2-0.9,2-2v-18C32,5.2,31.1,4.3,30,4.3z M16,22.3c-3.9,0-7-3.1-7-7s3.1-7,7-7c3.9,0,7,3.1,7,7S19.9,22.3,16,22.3z"
		/>
	</Icon>
);

const MyDataEdit: React.VFC = () => {
	const [nome, setNome]                     = useState("");
	const [email, setEmail]                   = useState("");
	const [phone, setPhone]                   = useState("");
	const [error, setError]                   = useState("");
	const [showNomeError, setShowNomeError]   = useState(false);
	const [showEmailError, setShowEmailError] = useState(false);
	const [showPhoneError, setShowPhoneError] = useState(false);
	const [isLoading, setIsLoading]           = useState(false);
	const [isSavingAvatar, setIsSavingAvatar] = useState(false);

	const dispatch = useDispatch();
	const userData = useSelector((state) => state.auth.userData);

	const history = useHistory();

	useEffect(() => {
		setNome(userData.name);
		setEmail(userData.email);
		setPhone(userData.phone);

		document.getElementById('name').value  = userData.name;
		document.getElementById('unity').value = `${userData.unity_name}, ${userData.unity_location}`;
		document.getElementById('email').value = userData.email;
	}, []);

	function validateForm() {
		if( !nome )
		{
			setShowNomeError(true);
		}
		else
		{
			setShowNomeError(false);
		}

		if( !email )
		{
			setShowEmailError(true);
		}
		else
		{
			setShowEmailError(false);
		}

		if( !phone )
		{
			setShowPhoneError(true);
		}
		else
		{
			setShowPhoneError(false);
		}

		if( !nome || !email || !phone )
		{
			return false;
		}

		setIsLoading(true);

		const data = {
			name : nome,
			email: email,
			phone: phone,
		};

		authService.edit(data)
		.then((response) => {
			toast.success('Dados alterados com sucesso!', {
				theme    : "dark",
				autoClose: 4000
			});

			dispatch(authActions.editUserData(response.data.data));

			history.push("/mydata");
			setIsLoading(false);
		})
		.catch((data) => {
			setIsLoading(false);
			setError(String(data));
		});
	}

	function onChangeAvatar(e) {
		const {files} = e.target;

		setIsSavingAvatar(true);

		const data = {
			avatar: files.length ? files[0] : null,
		};

		authService.changeAvatar(data)
		.then((response) => {
			setIsSavingAvatar(false);

			toast.success('Avartar alterado com sucesso!', {
				theme    : "dark",
				autoClose: 4000
			});

			// Update user avatar
			dispatch(authActions.editUserAvatar(response.data.file_url));
		})
		.catch((data) => {
			setIsSavingAvatar(false);

			toast.error(String(data), {
				theme    : "dark",
				autoClose: 4000
			});
		});
	}

	return (
		<>
			<LeftMenu />
			<Grid templateColumns="repeat(12, 1fr)">
				<GridItem colSpan={12} marginLeft={{base: 0, md: "140px"}}>
					<ReturnHeader title="EDITAR DADOS" subtitle="" to="/mydata" />
					<Box
						px={{base: "20px", md: "60px"}}
						width="100%"
						minH={{
							base: "calc(100vh - 477px)",
							md  : "calc(100vh - 224px)",
						}}
						bgGradient="linear(#18181A, gray.300)"
						pt="8"
						pb="12"
					>
						<Grid templateColumns="repeat(12, 1fr)">
							<GridItem colSpan={{base: 12, md: 6}}>
								<Flex justifyContent={{base: "center", md: "flex-start"}}>
									<Circle size={{base: '130px', md: '180px'}} bg="#2c2c30" color="white">
										{isSavingAvatar ? (
											<Spinner
												color='white.500'
												size="xl"
												position="absolute"
											/>
										) : (
											<>
												{userData.avatar ? (
													<Image
														position="absolute"
														borderRadius='full'
														boxSize={{base: '130px', md: '180px'}}
														src={userData.avatar}
														alt="Avatar"
													/>
												) : (
													<Icon
														viewBox="0 0 88.8 92"
														boxSize={{base: 10, md: 16}}
														position="absolute"
													>
														<path
															fill="#9699a2"
															d="M44.4,46.8C31.5,46.8,21,36.3,21,23.4S31.5,0,44.4,0s23.4,10.5,23.4,23.4S57.3,46.8,44.4,46.8z M44.4,8 C35.9,8,29,14.9,29,23.4s6.9,15.4,15.4,15.4s15.4-6.9,15.4-15.4S52.9,8,44.4,8z"
														/>
														<path
															fill="#9699a2"
															d="M84.8,92c-2.2,0-4-1.8-4-4V74.4c0-2.8-2.3-5-5-5H13c-2.8,0-5,2.3-5,5V88c0,2.2-1.8,4-4,4s-4-1.8-4-4V74.4 c0-7.2,5.8-13,13-13h62.7c7.2,0,13,5.8,13,13V88C88.8,90.2,87,92,84.8,92z"
														/>
													</Icon>
												)}
											</>
										)}

										<InputGroup
											css={`
												path {
													transition:0.1s ease;
												}
												&:hover {
													svg path {
														fill:#0d587a;
													}
												}
											`}
										>
											<FormLabel htmlFor="image">
												<Tooltip label="Trocar foto" placement="top">
													<Circle
														width={{base: "45px", md: "70px"}}
														height={{base: "45px", md: "70px"}}
														minWidth={{base: "45px", md: "70px"}}
														bg="custom.50"
														color="white"
														cursor="pointer"
														mt={{base: '95px', md: '125px'}}
														ml={{base: '65px', md: '100px'}}
													>
														<EditImageIcon />
													</Circle>
												</Tooltip>
											</FormLabel>
											<Input
												type="file"
												id="image"
												onChange={onChangeAvatar}
												tabIndex="-1"
												accept=".jpg,.png"
												style={{position: "absolute", top: -9999, left: -9999, width: 0}}
											/>
										</InputGroup>
									</Circle>
								</Flex>

								{!!error && <Alert status='error' mt={10}>
									<AlertIcon />
									<AlertDescription>{error}</AlertDescription>
								</Alert>}

								<TMGInput
									label="Nome"
									name="name"
									type="text"
									marginTop="8"
									maxLength={50}
									onChange={(e) => setNome(e.target.value)}
									onFocus={() => setShowNomeError(false)}
									css={showNomeError && !nome && `
										border-color: #ff4d4d;
										color: #ff4d4d;
										&::placeholder {
											color: #ff4d4d;
										}
									`}
								/>
								<Text display={showNomeError && !nome ? 'block' : 'none'} textAlign="left" color="#ff4d4d" mt="2" width="100%">Informe o nome</Text>

								<TMGInput
									label="Unidade"
									name="unity"
									type="text"
									disabled
									marginTop="3"
									css={`
										pointer-events:none;
										opacity:0.7;
									`}
								/>

								<TMGInput
									label="E-mail"
									name="email"
									type="text"
									marginTop="3"
									maxLength={191}
									onChange={(e) => setEmail(e.target.value)}
									onFocus={() => setShowEmailError(false)}
									css={showEmailError && !email && `
										border-color: #ff4d4d;
										color: #ff4d4d;
										&::placeholder {
											color: #ff4d4d;
										}
									`}
								/>
								<Text display={showEmailError && !email ? 'block' : 'none'} textAlign="left" color="#ff4d4d" mt="2" width="100%">Informe o e-mail</Text>

								<TMGInput
									label="Telefone"
									name="phone"
									type="tel"
									marginTop="3"
									mask={[
										// TODO removido a mascara sem o nono digito, por que estava enviando cortando o ultimo numero
										//{mask: '(00) 0000-0000'},
										{mask: '(00) 00000-0000'},
									]}
									onChange={(e) => setPhone(e.target.value)}
									onFocus={() => setShowPhoneError(false)}
									css={showPhoneError && !phone && `
										border-color: #ff4d4d;
										color: #ff4d4d;
										&::placeholder {
											color: #ff4d4d;
										}
									`}
									// Fix, campo com input que tiver valor inicial, precisa ter o defaultValue
									defaultValue={userData.phone}
								/>
								<Text display={showPhoneError && !phone ? 'block' : 'none'} textAlign="left" color="#ff4d4d" mt="2" width="100%">Informe o telefone</Text>

								<Button
									isLoading={isLoading}
									colorScheme="blue"
									bg="blue.200"
									size="xlg"
									width="100%"
									mt="10"
									py="6"
									fontWeight="regular"
									_hover={{textDecoration: "none", bg: '#266987'}}
									onClick={validateForm}
								>
									SALVAR
								</Button>
							</GridItem>
						</Grid>
					</Box>
					<Footer />
				</GridItem>
			</Grid>
		</>
	);
};

export { MyDataEdit };
