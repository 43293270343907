//@ts-nocheck

import React, { Component } from "react";
import { connect } from "react-redux";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Echo from "laravel-echo";
import { theme } from "./../../theme";
import "./../../App.scss";

import 'leaflet/dist/leaflet.css';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ROUTES } from "./../../config/routes";

import Error404 from "./../../screens/Error404";

import { IS_DEBUG, ROUTE_PATH, WS_URL, WS_PORT, WS_AUTH_URL, PUSHER_APP_KEY } from "./../../config/general";

import { authActions } from "./../../redux/actions";

import moment from "moment";

moment.locale("pt-br");

window.Pusher = require('pusher-js');

class Main extends Component {
	constructor(props) {
		super(props);

		window.Echo = null;
	}

	componentDidMount() {
		if( this.props.isAuthenticated )
		{
			// Get user data
			this.props.refreshUserData();

			// Init socket
			this.webSocketInit();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if( prevProps.isAuthenticated !== this.props.isAuthenticated )
		{
			// Login
			if( this.props.isAuthenticated )
			{
				// Init socket
				this.webSocketInit();
			}
			// Logout
			else
			{
				// Websocket destroy
				this.webSocketDestroy();
			}
		}
	}

	componentWillUnmount() {
		// Websocket destroy
		this.webSocketDestroy();
	}

	webSocketInit = () => {
		if( window.Echo !== null )
		{
			return false;
		}

		const {accessToken} = this.props;

		window.Echo = new Echo({
			broadcaster : 'pusher',
			key         : PUSHER_APP_KEY,
			wsHost      : WS_URL,
			wsPort      : WS_PORT,
			wssPort     : WS_PORT,
			forceTLS    : !IS_DEBUG,
			disableStats: true,
			authEndpoint: WS_AUTH_URL,
			auth        : {
				headers: {
					Authorization: accessToken,
				},
			},
		});
	}

	webSocketDestroy = () => {
		if( window.Echo === null )
		{
			return false;
		}

		// Disconnect socket
		window.Echo.disconnect();

		window.Echo = null;
	}

	render() {
		return (
			<ChakraProvider theme={theme}>
				<BrowserRouter basename={ROUTE_PATH}>
					<Switch>
						{ROUTES.map((route, i) => (
							<Route
								key={i}
								exact={route?.exact ?? true}
								path={route.path}
								component={(props) => {
									if( route.hasOwnProperty('logged') )
									{
										// Only logged
										if( route.logged && !this.props.isAuthenticated )
										{
											const currentUrl = new URL(window.location.href);

											return <Redirect to={{pathname: "/login", search: `?origin=${encodeURIComponent(`${currentUrl.pathname}${currentUrl.search}`)}`}} />;
										}
										// Only logged out
										else if( !route.logged && this.props.isAuthenticated )
										{
											return <Redirect to="/account" />;
										}
									}

									return <route.component {...props} />;
								}}
							/>
						))}
						<Route path="*" component={Error404} />
					</Switch>
				</BrowserRouter>
				<ToastContainer
					position="top-right"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
			</ChakraProvider>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		isAuthenticated: state.auth.isAuthenticated,
		accessToken    : state.auth.accessToken,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		refreshUserData: () => {
			dispatch(authActions.refreshUserData());
		},
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
