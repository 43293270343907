//@ts-nocheck

import { GridItem } from "@chakra-ui/layout";
import {
	Box,
	Flex,
	Text,
	Spinner,
} from "@chakra-ui/react";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { LeftMenu } from "../../components/LeftMenu";
import { PackageItem } from "../../components/PackageItem";
import { Details } from "./Details";
import styles from './styles.module.scss'
import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router";
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from "axios";
import { toast } from 'react-toastify';
import moment from 'moment';

import { API_ERRO_TYPE_CANCEL } from "../../config/general";

import { localService, packageService } from "../../redux/services";

const History: React.VFC = () => {
	const [state, setState] = useState({
		isLoading   : true,
		isRefreshing: false,
		data        : [],
		hasError    : false,
		error       : '',
		pagination  : {
			current : 1,
			pageSize: 50,
			total   : 0,
			finished: false,
		},
		search      : '',
		// Filters
		locationsIsLoading: true,
		locations         : [],
		locationSelected  : {
			uuid: 'all',
			name: 'Todos',
		},
		filters           : {
			start: "",
			end  : "",
			bag  : "",
			in   : false,
			out  : false,
		},
	});

	const _showScreen  = useRef(null);
	const _firstRender = useRef(true);
	const _cancelToken = useRef(null);

	// Change filters
	useEffect(() => {
		if( _firstRender.current ) return false;

		// Fetch all
		fetchGetAll(true);

	}, [state.search, state.locationSelected, state.filters]);

	useEffect(() => {
		_firstRender.current = false;

		// Fetch locations
		fetchLocations();

		// Fetch all
		fetchGetAll(true);

		return function cleanup() {
			_cancelToken.current && _cancelToken.current.cancel("Landing Component got unmounted");
		};
	}, []);

	function fetchGetAll(init = false) {
		const {isLoading, isRefreshing, pagination, search, locationSelected, filters} = state;

		if( init )
		{
			setState(prevState => ({
				...prevState,
				isLoading: true,
				data     : [],
				hasError : false,
				error    : '',
			}));
		}
		else
		{
			if( isLoading || isRefreshing || pagination.finished )
			{
				return false;
			}

			setState(prevState => ({
				...prevState,
				isRefreshing: true,
			}));
		}

		if( _cancelToken.current )
		{
			_cancelToken.current.cancel("Only one request allowed at a time.");
		}

		_cancelToken.current = axios.CancelToken.source();

		const data = {
			limit  : pagination.pageSize,
			orderBy: 'last_history_date:desc',
			page   : init ? 1 : pagination.current + 1,
			search : search,
		};

		// Search
		if( search )
		{
			data.search = search;
		}

		// Location
		if( locationSelected.uuid !== 'all' )
		{
			data.location = locationSelected.uuid;
		}

		// Date
		if( filters.start || filters.end )
		{
			data.last_history_date = [
				filters.start ? moment(filters.start).startOf("day").format("YYYY-MM-DDTHH:mm:ssZ") : null,
				filters.end ? moment(filters.end).endOf("day").format("YYYY-MM-DDTHH:mm:ssZ") : null,
			];
		}

		// Bag
		if( filters.bag )
		{
			data.bag_name = filters.bag;
		}

		// Status
		if( filters.in && filters.out )
		{
			//
		}
		else if( filters.in )
		{
			data.status = 'input';
		}
		else if( filters.out )
		{
			data.status = 'output';
		}

		packageService.getAll(data, _cancelToken.current.token)
		.then((response) => {
			setState(prevState => ({
				...prevState,
				isLoading   : false,
				isRefreshing: false,
				data        : init ? response.data.data : [...prevState.data, ...response.data.data.filter(item => !prevState.data.some(item_ => item_.uuid === item.uuid))],
				pagination  : {
					...prevState.pagination,
					current : response.data.meta.current_page,
					total   : response.data.meta.total,
					finished: !response.data.links.next,
				},
			}));
		})
		.catch((data) => {
			if( data?.error_type === API_ERRO_TYPE_CANCEL ) return null;

			if( init )
			{
				setState(prevState => ({
					...prevState,
					isLoading   : false,
					isRefreshing: false,
					hasError    : true,
					error       : String(data),
				}));
			}
			else
			{
				setState(prevState => ({
					...prevState,
					isLoading   : false,
					isRefreshing: false,
				}));

				toast.error(String(data), {
					theme    : "dark",
					autoClose: 4000
				});
			}
		});
	}

	function fetchLocations() {
		setState(prevState => ({
			...prevState,
			locationsIsLoading: true,
		}));

		const data = {
			orderBy         : 'name:asc',
			onlyWithPackages: 1,
		};

		localService.getAll(data)
		.then((response) => {
			const locations = response.data.data.map((item) => ({uuid: item.uuid, name: item.name}));

			// Add "all" on start
			locations.unshift({
				uuid: 'all',
				name: 'Todos',
			});

			setState(prevState => ({
				...prevState,
				locationsIsLoading: false,
				locations         : locations,
			}));
		})
		.catch((data) => {
			setState(prevState => ({
				...prevState,
				locationsIsLoading: false,
			}));
		});
	}

	function onSelectLocation(item) {
		setState(prevState => ({
			...prevState,
			locationSelected: item,
		}));
	}

	function onSearch(value) {
		setState(prevState => ({
			...prevState,
			search: value,
		}));
	}

	function onFilter(filters) {
		setState(prevState => ({
			...prevState,
			filters: {
				...prevState.filters,
				...filters,
			},
		}));
	}

	function onItemClick(id) {
		_showScreen.current.open(id);
	}

	return (
		<>
			<LeftMenu />
			<Flex width="100%">
				<Details ref={_showScreen} />
				<GridItem width="100%" marginLeft={{base: 0, md: "140px"}}>
					<Header
						selectedOption={state.locationSelected}
						setSelectedOption={onSelectLocation}
						search={state.search}
						setSearch={onSearch}
						filter={state.filters}
						locations={state.locations}
						setFilter={onFilter}
					/>
					<Box
						position="relative"
						pt="8"
						pb="12"
						width="100%"
						minH={{
							base: "calc(100vh - 477px)",
							md  : "calc(100vh - 224px)",
						}}
						bgGradient="linear(#18181A, gray.300)">
						{" "}
						<Flex
							paddingLeft={{base: "4px", md: "44px"}}
							paddingRight={{base: "4px", md: "44px"}}
							justifyContent="space-between"
							mb={{base: '2', md: '6'}}
							mx="4">
							<Flex w="100%">
								{!state.isLoading && (
									<Text
										fontSize={{base: "14px", md: "18px"}}
										textTransform="uppercase">
										{state.search ? `${state.pagination.total} pacote${state.pagination.total === 1 ? ' encontrado' : 's encontrados'} para "${state.search}".` : `Exibindo ${state.pagination.total} pacote${state.pagination.total === 1 ? '' : 's'}`}
									</Text>
								)}
							</Flex>
						</Flex>
						<div>
							<InfiniteScroll
								dataLength={state.data.length}
								next={() => fetchGetAll()}
								hasMore={!state.pagination.finished}
								loader={<Box mt="10" display="flex" justifyContent="center"><Spinner color="#fff" size="lg" /></Box>}
								pullDownToRefreshThreshold={50}
								scrollThreshold={0.98}>
								{state.data.map((item) => (
									<PackageItem
										key={item.uuid}
										onClick={onItemClick}
										id={item.uuid}
										name={item.name}
										date={item.last_history_date}
										status={item.status}
										styles={styles}
									/>
								))}
							</InfiniteScroll>
						</div>
					</Box>
					<Footer />
				</GridItem>
			</Flex>
		</>
	);
};

export { History };
