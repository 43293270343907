//@ts-nocheck

import Icon from "@chakra-ui/icon";
import { Box, Center, Text } from "@chakra-ui/layout";
import { useLocation } from "react-router";

const RealTimeBox: React.VFC = () => {
  const location = useLocation();
  const color = location.pathname.match("/realtime") ? "#444444" : "gray.50";
  return (
    <Box
      w="100%"
      height="50px"
      textAlign="center"
      style={{ marginTop: "80px" }}
    >
      <Center>
        <Icon viewBox="0 0 70 70" color={color} width="18%" boxSize={8}>
          <path
            fill="currentColor"
            d="M1418.5,408a31.5,31.5,0,1,0,31.5,31.5A31.53,31.53,0,0,0,1418.5,408Zm0,58a26.5,26.5,0,1,1,26.5-26.5A26.53,26.53,0,0,1,1418.5,466Z"
            transform="translate(-1387 -408)"
          />
          <path
            fill="currentColor"
            d="M1433.14,450.29,1421,441.43v-19a2.5,2.5,0,0,0-5,0V442.7a2.49,2.49,0,0,0,1,2l13.16,9.61a2.5,2.5,0,1,0,3-4Z"
            transform="translate(-1387 -408)"
          />
        </Icon>
      </Center>
      <Text mt="2" fontSize="14" color={color}>
        EM TEMPO REAL
      </Text>
    </Box>
  );
};

export { RealTimeBox };
