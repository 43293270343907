//@ts-nocheck

import {
	Alert,
	AlertIcon,
	AlertDescription,
	Button,
	Img,
	Link,
	Box,
	Text,
} from "@chakra-ui/react";
import { Footer } from "../../components/Footer";
import { TMGInput } from "../../components/Input";
import { Link as ReactRouterLink, useHistory, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { apiUpdateAccessToken } from "../../config/api";

import { CLIENT_DATA } from "../../config/general";

import { authActions } from "../../redux/actions";

import { authService } from "../../redux/services";

const Login: React.VFC = () => {
	const [email, setEmail]                   = useState("");
	const [pass, setPass]                     = useState("");
	const [error, setError]                   = useState("");
	const [showEmailError, setShowEmailError] = useState(false);
	const [showPassError, setShowPassError]   = useState(false);
	const [isLoading, setIsLoading]           = useState(false);

	const dispatch = useDispatch();

	const history  = useHistory();
	const location = useLocation();

	function clearStates() {
		setEmail("");
		setPass("");
		setShowEmailError(false);
		setShowPassError(false);
		setIsLoading(false);
	}

	useEffect(() => {
		clearStates();
	}, [location]);

	function getRedirectTo() {
		return new URLSearchParams(location.search).get('origin') ?? '/';
	}

	function submitForm(e) {
		e.preventDefault();

		setIsLoading(true);

		if( !email )
		{
			setShowEmailError(true);
			setIsLoading(false);
		}
		else
		{
			setShowEmailError(false);
		}
		if( !pass )
		{
			setShowPassError(true);
			setIsLoading(false);
		}
		else
		{
			setShowPassError(false);
		}

		if( email && pass )
		{
			const data = {
				email     : email,
				password  : pass,
				token_name: `${CLIENT_DATA.browser_name} - ${CLIENT_DATA.os_name}`,
			};

			let accessToken = '';

			authService.login(data)
			.then((response) => {
				accessToken = response.data.access_token;

				// Update accessToken from api instance
				apiUpdateAccessToken(`Bearer ${accessToken}`);

				// Get user data
				return authService.getUserData();
			})
			.then((response) => {
				setIsLoading(false);

				// Do Login
				dispatch(authActions.login({
					accessToken: accessToken,
					...response.data.data,
				}));

				// Redirect
				history.replace(getRedirectTo());
			})
			.catch((data) => {
				setIsLoading(false);
				setError(String(data));
			});
		}
	}

	return (
		<>
			<Box
				d="flex"
				margin="0 auto"
				height={{base: '85vh', md: '90vh'}}
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
				px="20px"
				width="100%"
				maxWidth="580px"
			>
				<Img src="./logo.svg" width={{base: '140px', md: '200px'}} mb="5" />
				{!!error && <Alert status='error' mt={5}>
					<AlertIcon />
					<AlertDescription>{error}</AlertDescription>
				</Alert>}
				<form onSubmit={submitForm} style={{width:"100%"}}>
					<TMGInput
						label="Usuário ou e-mail"
						name="email"
						type="email"
						marginTop="8"
						onChange={(e) => setEmail(e.target.value)}
						onFocus={() => setShowEmailError(false)}
						css={showEmailError && !email && `
            border-color: #ff4d4d;
            color: #ff4d4d;
            &::placeholder {
              color: #ff4d4d;
            }
          `}
					/>
					<Text display={showEmailError && !email ? 'block' : 'none'} textAlign="left" color="#ff4d4d" mt="2" width="100%">Informe o e-mail</Text>
					<TMGInput
						label="Senha"
						name="password"
						type="password"
						marginTop="3"
						onChange={(e) => setPass(e.target.value)}
						onFocus={() => setShowPassError(false)}
						css={showPassError && !pass && `
            border-color: #ff4d4d;
            color: #ff4d4d;
            &::placeholder {
              color: #ff4d4d;
            }
          `}
					/>
					<Text display={showPassError && !pass ? 'block' : 'none'} textAlign="left" color="#ff4d4d" mt="2" width="100%">Informe a senha</Text>
					<Button
						type="submit"
						isLoading={isLoading}
						colorScheme="blue"
						size="xlg"
						bg="blue.200"
						width="100%"
						mt="10"
						py={{base: '4', md: '6'}}
						fontWeight="regular"
						_hover={{textDecoration: "none", bg: '#266987'}}
					>
						ENTRAR
					</Button>
				</form>
				<Link
					as={ReactRouterLink}
					to="/forgot"
					mt="10"
					fontSize={{base: "16px", md: "18px"}}
					color="#676870"
					_hover={{color: "white"}}
				>
					ESQUECI MINHA SENHA
				</Link>
			</Box>
			<Footer />
		</>
	);
};

export { Login };
