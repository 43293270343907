//@ts-nocheck

import { HStack } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { HistoryBox } from "./Boxes/history";
import { MyDataBox } from "./Boxes/mydata";
import { RealTimeBox } from "./Boxes/realtime";

const BottomMenu: React.VFC = () => {
	return (
		<HStack
			position="fixed"
			zIndex="9"
			bottom="0"
			w="100vw"
			background="#141316"
			spacing="10px"
			height="80px"
			display={{base: "flex", md: "none"}}
			justifyContent="space-evenly">
			<NavLink to="/">
				<HistoryBox />
			</NavLink>
			<NavLink to="/realtime">
				<RealTimeBox />
			</NavLink>
			<NavLink to="/mydata">
				<MyDataBox />
			</NavLink>
		</HStack>
	);
};

export { BottomMenu };
