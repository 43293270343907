//@ts-nocheck

import "./styles.module.scss";

import { Input, InputGroup, InputLeftElement } from "@chakra-ui/input";
import { Box, Img } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { Dispatch, SetStateAction } from "react";

type Props = {
	search: string;
	isSearch?: boolean;
	isApplied?: boolean;
	setSearch: Dispatch<SetStateAction<string>>;
	setIsSearch?: Dispatch<SetStateAction<boolean>>;
	setIsApplied?: Dispatch<SetStateAction<boolean>>;
};

const Search: React.VFC<Props> = ({ search, setSearch }) => {
	return (
		<InputGroup
			role="group"
			width="100%"
			maxWidth={{ base: "230px", lg: "360px" }}
			marginRight="20px"
		>
			<InputLeftElement
				pointerEvents="none"
				css={{ height: "100%" }}
				children={
					<SearchIcon
						boxSize={5}
						color="gray.50"
						_groupHover={{ color: "white" }}
						css={{
							transform: "scaleX(-1) translateY(-50%)",
							position: "absolute",
							top: "50%",
							left: "15px",
						}}
					/>
				}
			/>
			<Input
				type="search"
				placeholder="Buscar pacote"
				background="gray.200"
				borderColor="transparent"
				color="#ffffff"
				py="6"
				pl="12"
				onChange={(e) => setSearch(e.target.value)}
				value={search}
				_focus={{ border: "2px solid gray.200" }}
			/>
			<Box
				position="absolute"
				top="50%"
				transform="translateY(-50%)"
				right="10px"
				padding="10px"
				cursor="pointer"
				display={search ? "block" : "none"}
				onClick={() => setSearch("")}
			>
				<Img src="clear.png" alt="Limpar" width="12px" height="12px" />
			</Box>
		</InputGroup>
	);
};

export { Search };
