//@ts-nocheck

import { api } from "../../config/api";

const basePath = "packages";

/**
 * Get all
 *
 * @param {Object} options
 * @param cancelToken
 *
 * @returns {Promise<T>}
 */
export const getAll = (options, cancelToken) => {
	const options_default = {};

	// Merge config
	options = Object.assign({}, options_default, options);

	let params    = [];
	let params_qs = "";

	if( options.hasOwnProperty("page") )
	{
		params.push(`page=${options.page}`);
	}

	if( options.hasOwnProperty("limit") )
	{
		params.push(`limit=${options.limit}`);
	}

	if( options.hasOwnProperty("search") )
	{
		params.push(`search=${options.search}`);
	}

	if( options.hasOwnProperty("orderBy") )
	{
		params.push(`orderBy=${options.orderBy}`);
	}

	if( options.hasOwnProperty("date_input") )
	{
		options.date_input.forEach((item, index) => {
			params.push(`date_input[${index}]=${item}`);
		});
	}

	if( options.hasOwnProperty("date_output") )
	{
		options.date_output.forEach((item, index) => {
			params.push(`date_output[${index}]=${item}`);
		});
	}

	if( options.hasOwnProperty("last_history_date") )
	{
		options.last_history_date.forEach((item, index) => {
			params.push(`last_history_date[${index}]=${item ? item : ''}`);
		});
	}

	if( options.hasOwnProperty("created_at") )
	{
		options.created_at.forEach((item, index) => {
			params.push(`created_at[${index}]=${item}`);
		});
	}

	if( options.hasOwnProperty("location") )
	{
		params.push(`location=${options.location}`);
	}

	if( options.hasOwnProperty("status") )
	{
		params.push(`status=${options.status}`);
	}

	if( options.hasOwnProperty("bag_name") )
	{
		params.push(`bag_name=${options.bag_name}`);
	}

	if( params.length )
	{
		params_qs = `?${params.join("&")}`;
	}

	return api.get(`${basePath}${params_qs}`, {cancelToken});
};

/**
 * Show
 *
 * @param {Object} options
 * @param cancelToken
 *
 * @returns {Promise<T>}
 */
export const show = (options, cancelToken) => {
	return api.get(`${basePath}/${options.uuid}`, {cancelToken});
};
