export default {
	LOGIN: "AUTH_LOGIN",

	LOGOUT: "AUTH_LOGOUT",

	EDIT_USER_DATA: "AUTH_EDIT_USER_DATA",

	EDIT_USER_AVATAR: "AUTH_EDIT_USER_AVATAR",

	USERDATA_REQUEST: "AUTH_USERDATA_REQUEST",
	USERDATA_SUCCESS: "AUTH_USERDATA_SUCCESS",
	USERDATA_ERROR  : "AUTH_USERDATA_ERROR",
};
