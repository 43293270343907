//@ts-nocheck

import { Box, Icon, Flex, Text, Link } from "@chakra-ui/react";
import React from "react";
import { Link as ReactRouterLink } from "react-router-dom";

const ReturnHeader: React.VFC<{
  title: string;
  subtitle?: string;
  to: string;
  back?: boolean;
  Action?: React.FC<any>;
}> = ({ title, subtitle, to, back, Action }) => {
  if (back === undefined) {
    back = true;
  }
  return (
    <>
      <Box px={{ base: '20px', md: '60px' }} py="10" width="100%" background="gray.300" color="white">
        <Flex alignItems="center">
          {back && (
            <Flex
              width={{ base: "20%", md: "auto" }}
              maxWidth={{ base: "20%", md: "auto" }}
              justifyContent={{ base: "flex-start", md: "flex-start" }}
            >
              <Link as={ReactRouterLink} to={to}>
                <Icon viewBox="0 0 51.5 43" width="18%" boxSize={5}>
                  <path
                    fill="currentColor"
                    d="M49,19H8.6L23.3,4.3c1-1,1-2.6,0-3.5c-1-1-2.6-1-3.5,0L1,19.5c-0.6,0.5-1,1.2-1,2c0,0,0,0,0,0
	c0,0.6,0.2,1.3,0.7,1.8l19,19c0.5,0.5,1.1,0.7,1.8,0.7s1.3-0.2,1.8-0.7c1-1,1-2.6,0-3.5L8.5,24H49c1.4,0,2.5-1.1,2.5-2.5
	S50.4,19,49,19z"
                  />
                </Icon>
              </Link>
            </Flex>
          )}

          <Flex
            width={{ base: "60%", md: "100%" }}
            maxWidth={{ base: "60%", md: "100%" }}
            alignItems={{ base: "center", md: "flex-end" }}
            justifyContent={{ base: "center", md: "flex-start" }}
            direction={{ base: "column", md: "row" }}
            pl={{ base: 0, md: 6 }}
          >
            <Flex maxWidth="100%" height="fit-content">
              <Text
                fontSize={{ base: '22px', md: '32px' }}
                fontWeight="bold"
                color="white"
                isTruncated
              >
                {title}
              </Text>
            </Flex>
            <Flex
              alignItems={{ base: "center", md: "flex-end" }}
              justifyContent={{ base: "center", md: "flex-end" }}
              pl={{ base: 0, md: 6 }}
              pt={{ base: 1, md: 0 }}
              textAlign="center"
            >
              {subtitle && (
                <Text
                  color="gray.400"
                  textAlign="center"
                  isTruncated
                  fontSize={{ base: 14, md: 18 }}
                >
                  {subtitle}
                </Text>
              )}
            </Flex>
          </Flex>

          {Action && (
            <Flex
              width={{ base: "20%", md: "100%" }}
              maxWidth={{ base: "20%", md: "100%" }}
              justifyContent={"flex-end"}
            >
              <Action />
            </Flex>
          )}
        </Flex>
      </Box>
    </>
  );
};

export { ReturnHeader };
