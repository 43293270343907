//@ts-nocheck

import {
  Box,
  Flex,
  // MenuButton,
  // MenuItem,
  // Menu,
  // MenuList,
  Text,
} from "@chakra-ui/react";
import React from "react";
// import { HiOutlineDotsVertical } from "react-icons/hi";

const RealtimeHeader: React.VFC<{
  title: string;
}> = ({ title }) => {
  return (
    <>
      <Box px={{ base: '20px', md: '60px' }} py="10" width="100%" background="gray.300">
        <Flex>
            <Text
              fontWeight="bold"
              color="white"
              fontSize={{  base: '22px', md: '32px' }}
              width="100%"
              textAlign={{ base: 'center', md: 'left' }}
            >
              {title}
            </Text>
        </Flex>
      </Box>
    </>
  );
};

export { RealtimeHeader };
