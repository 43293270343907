//@ts-nocheck

import {
	Box,
	Grid,
	Flex,
	Img,
	Collapse,
	Text,
	useBreakpoint,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/menu";
import { FilteredItems } from "../FilteredItems";
import { Filter, FormFilter } from "../FormFilter";
import { Search } from "../Search";
import { SearchWithFilter } from "../SearchWithFilter";
import { MobileFilter } from "./MobileFilter";
import { useState, Dispatch, SetStateAction } from "react";

interface HeaderProps {
	search: string;
	setSearch: Dispatch<SetStateAction<string>>;
	filter: Filter;
	setFilter: Dispatch<SetStateAction<Filter>>;
	locations: Object[];
	selectedOption: any;
	setSelectedOption: Dispatch<SetStateAction<string>>;
}

const Header: React.VFC<HeaderProps> = ({search, setSearch, filter, setFilter, locations, selectedOption, setSelectedOption}) => {
	const [isSearch, setIsSearch]   = useState<boolean>(false);
	const [isApplied, setIsApplied] = useState<boolean>(false);
	const breakpoint                = useBreakpoint();

	function handleFormSubmit() {
		setIsSearch(false);
		setIsApplied(true);
	}

	if( isSearch && (breakpoint === "base" || breakpoint === "sm") )
	{
		return (
			<MobileFilter
				onBack={() => setIsSearch(false)}
				filter={filter}
				setFilter={setFilter}
				setIsApplied={setIsApplied}
			/>
		);
	}

	return (
		<>
			<Box
				width="100%"
				background="gray.300"
				height={{base: "250px", md: "138px"}}
			>
				<Flex
					paddingLeft={{base: "20px", md: "60px"}}
					paddingRight={{base: "20px", md: "60px"}}
					height="100%"
					alignItems="center"
					justifyContent={{base: "center", md: "space-between"}}
					wrap={{base: "wrap", md: "nowrap"}}
					gridRowGap="16px"
					gridColumnGap="16px"
				>
					<Flex
						alignItems="center"
						wrap={{base: "wrap", md: "nowrap"}}
						gridRowGap="16px"
						justifyContent={{base: "center", md: "space-between"}}
						width={{base: "100%", md: "unset"}}
					>
						<Img
							mt={{base: "30px", md: "0"}}
							src="./logo.svg"
							width="100px"
						/>
						<Flex width={{base: "100%", md: "unset"}} justifyContent="center">
							<Menu>
								<MenuButton
									marginLeft={{base: "0px", md: "20px", lg: "50px"}}
									transition="all 0.2s"
									_hover={{color: "white"}}
									_active={{color: "white"}}
									role="group"
								>
									<Flex alignItems="center" justifyContent="center">
										<Box
											background="gray.200"
											borderRadius="100"
											width="50px"
											height="50px"
											minWidth="50px"
											d="flex"
											alignItems="center"
											justifyContent="center"
											transition="0.1s"
											_groupHover={{background: "#3e3e42"}}
											_groupActive={{background: "#3e3e42"}}
										>
											<ChevronDownIcon w={6} h={6} />
										</Box>
										<Text ml="3" color="white" fontSize={{base: "16px", md: "14px", lg: "16px"}} wordBreak="break-all">
											{selectedOption.name}
										</Text>
									</Flex>
								</MenuButton>
								<MenuList background="gray.200" p="4" border="0" zIndex={3}>
									{locations
									.filter((item) => item.uuid !== selectedOption.uuid)
									.map((item, idx) => (
										<MenuItem
											key={`menuItemSelector-${idx}`}
											color="#fff"
											background="gray.200"
											_hover={{background: "transparent"}}
											_focus={{background: "transparent"}}
											onClick={() => setSelectedOption(item)}>
											{item.name}
										</MenuItem>
									))}
								</MenuList>
							</Menu>
						</Flex>
					</Flex>
					<Flex
						alignItems="center"
						justifyContent="center"
						// mb={{ base: "30px", md: "0" }}
						width={{base: "100%", md: "unset"}}
					>
						<Search search={search} setSearch={setSearch} />
						<SearchWithFilter
							search={search}
							isSearch={isSearch}
							isApplied={isApplied}
							setIsSearch={setIsSearch}
							setIsApplied={setIsApplied}
						/>
					</Flex>
				</Flex>
				{/* <Grid templateColumns="repeat(12, 1fr)">
          <GridItem
            colSpan={{ base: 12, md: 1 }}
            d="flex"
            alignItems="center"
            marginBottom={{ base: "2rem", md: 0 }}
            justifyContent={{ base: "center", md: "flex-start" }}
          >
            <Img src="./logo.svg" width="100px" />
          </GridItem>
          <GridItem
            colSpan={{ base: 12, md: 6 }}
            marginBottom={{ base: "2rem", md: 0 }}
            d="flex"
            alignItems="center"
            justifyContent={{ base: "center", md: "flex-start" }}
          >
            <Selector />
          </GridItem>
          <GridItem
            colSpan={{ base: 9, md: 4 }}
            d="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Search setSearch={setSearch} search={search} />
          </GridItem>
          <GridItem
            marginLeft={{ base: "1rem", md: 0 }}
            colSpan={{ base: 3, md: 1 }}
            d="flex"
            alignItems="center"
            justifyContent="center"
          >
            <SearchWithFilter
              search={search}
              isSearch={isSearch}
              isApplied={isApplied}
              setIsSearch={setIsSearch}
              setIsApplied={setIsApplied}
            />
          </GridItem>
        </Grid> */}
			</Box>
			<Collapse in={isSearch} unmountOnExit animateOpacity>
				{(isSearch) && (
					<Box
						paddingLeft={{base: "20px", md: "60px"}}
						paddingRight={{base: "20px", md: "60px"}}
						pb="10"
						width="100%"
						background="gray.300"
					>
						<Flex wrap="wrap" justifyContent="space-between">
							<FormFilter
								filter={filter}
								setFilter={setFilter}
								onSubmit={handleFormSubmit}
							/>
						</Flex>
					</Box>
				)}
			</Collapse>
			{isApplied && (breakpoint === "base" || breakpoint === "sm") ? (
					<Box px={{base: "20px", md: "60px"}} mb="10">
						{isApplied && (
							<Flex wrap="wrap" gridGap={4}>
								<FilteredItems
									filter={filter}
									setFilter={setFilter}
									setIsApplied={setIsApplied}
								/>
							</Flex>
						)}
					</Box>
				) :
				isApplied ?
					<Box
						paddingLeft={{base: "20px", md: "60px"}}
						paddingRight={{base: "20px", md: "60px"}}
						pb="10"
						width="100%"
						background="gray.300"
					>
						<Grid templateColumns="repeat(12, 1fr)" gap={4}>
							<FilteredItems
								filter={filter}
								setFilter={setFilter}
								setIsApplied={setIsApplied}
							/>
						</Grid>
					</Box>
					: <></>
			}
		</>
	);
};

export { Header };
