//@ts-nocheck

import { forwardRef, useImperativeHandle, useRef, useState } from "react";

import {
	Box,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	Grid,
	GridItem,
	Img,
	Spinner,
	Text,
	useDisclosure,
} from "@chakra-ui/react";
import { LabelValue } from "../../../components/LabelValue";
import { IoMdClose } from "react-icons/io";
import axios from "axios";
import { toast } from 'react-toastify';
import moment from 'moment';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';

import { API_ERRO_TYPE_CANCEL } from "../../../config/general";

import { packageService } from "../../../redux/services";

const Details: React.VFC<Props> = forwardRef((props, ref: Ref) => {
	const {isOpen, onClose, onOpen} = useDisclosure();

	const [state, setState] = useState({
		isLoading: true,
		item     : null,
	});

	const _cancelToken = useRef(null);

	useImperativeHandle(ref, () => ({
			open(id) {
				show(id);
			},
			close() {
				close();
			},
		}),
	)

	function show(id) {
		onOpen();

		fetchInitial(id);
	}

	function close() {
		onClose();

		_cancelToken.current && _cancelToken.current.cancel("Landing Component got unmounted");
	}

	function fetchInitial(id) {
		setState(prevState => ({
			...prevState,
			isLoading: true,
		}));

		_cancelToken.current = axios.CancelToken.source();

		setState(prevState => ({
			...prevState,
			isLoading: true,
			hasError : false,
			error    : "",
		}));

		packageService.show({uuid: id}, _cancelToken.current.token)
		.then((response) => {
			setState(prevState => ({
				...prevState,
				isLoading: false,
				item     : response.data.data,
			}));
		})
		.catch((data) => {
			if( data?.error_type === API_ERRO_TYPE_CANCEL ) return null;

			setState(prevState => ({
				...prevState,
				isLoading: false,
			}));

			toast.error(String(data), {
				theme    : "dark",
				autoClose: 4000
			});

			close();
		});
	}

	return (
		<Drawer
			innerRef={ref}
			isOpen={isOpen}
			onClose={close}
			size="xl">
			<DrawerOverlay />
			<DrawerContent backgroundColor="#1C1E1F" padding={{base: 2, md: 10}}>
				<DrawerHeader display="flex" justifyContent="space-between">
					<IoMdClose cursor="pointer" style={{marginLeft: '-6px'}} color="#ffffff" onClick={close} size={36} />
				</DrawerHeader>
				<DrawerBody>
					{state.isLoading ? (
						<Box mt="10" display="flex" justifyContent="center"><Spinner color="#fff" size="lg" /></Box>
					) : (
						<>
							<Text fontSize={{base: '22px', md: '32px'}} color="#fff" fontWeight="700" mb="6">
								{state.item.name}
							</Text>
							<Grid templateColumns={{base: '100%', md: '50% 50%'}}>
								<GridItem mb={{base: "4", md: "6"}}>
									<LabelValue label="Localização" value={state.item.location.name} />
								</GridItem>
								<GridItem mb={{base: "4", md: "6"}}>
									<LabelValue label="Bag" value={state.item.bag?.name ?? '-'} />
								</GridItem>
							</Grid>
							<Grid templateColumns={{base: '100%', md: '50% 50%'}}>
								<GridItem mb={{base: "4", md: "0"}}>
									<LabelValue label="Data de entrada" value={state.item.date_input ? moment(state.item.date_input).format('DD/MM/YYYY') : '-'} />
								</GridItem>
								<GridItem>
									<LabelValue label="Data de saída" value={state.item.date_output ? moment(state.item.date_output).format('DD/MM/YYYY') : '-'} />
								</GridItem>
							</Grid>
							<LabelValue
								label="Anotação"
								fontSizeLabel="12px"
								fontSizeSubtitle="18px"
								value={state.item.observations ?? '-'}
								backgroundColor="#252529"
								p="6"
								mt="25px"
								borderRadius="8px"
							/>
							{state.item.history.length > 0 && (
								<Box my="8" width="100%" pt="20px">
									<Text fontSize={{base: "16px", md: "18px"}} mb={{base: '4', md: '9'}} color="#676870">
										HISTÓRICO DE MOVIMENTAÇÃO
									</Text>
									{state.item.history.map((data, idx) => (
										<Flex
											key={idx}
											alignItems="center"
											borderBottom={{base: '', md: '1px solid #28282A'}}>
											<Box
												backgroundColor={data.status === 'input' ? "#202c30" : "#311f1e"}
												borderRadius="10px"
												height={{base: '40px', md: '50px'}}
												width={{base: '40px', md: '50px'}}
												minWidth={{base: '40px', md: '50px'}}
												display="flex"
												justifyContent="center"
												alignItems="center">
												<Img
													margin="0 auto"
													src={data.status === 'input' ? "./icone-seta-down.png" : "./icone-seta-up.png"}
													height={{base: '14px', md: '18px'}}
													width={{base: '14px', md: '18px'}}
												/>
											</Box>
											<Flex
												alignItems="center"
												justifyContent="space-between"
												wrap="wrap"
												marginLeft="25px"
												width="100%"
												height="100%"
												py="6"
												borderBottom={{base: '1px solid #28282A', md: '0'}}>
												<Text
													fontSize={{base: "16px", md: "20px"}}
													color="white"
													height="fit-content"
													width={{base: "100%", md: "fit-content"}}>
													{`${data.status === 'input' ? 'Entrada em' : 'Saída do'} ${data.location.name}`}
												</Text>
												<Text
													fontSize={{base: "14px", md: "18px"}}
													height="fit-content"
													width={{base: "100%", md: "fit-content"}}>
													{moment(data.created_at).format('DD/MM/YYYY HH:mm')}
												</Text>
											</Flex>
										</Flex>
									))}
								</Box>
							)}
							<Box
								position="relative"
								height={300}
								overflow="hidden"
								borderRadius={12}
								filter="invert(90%)">
								<MapContainer
									center={[state.item.lat, state.item.lon]}
									zoom={17}
									maxZoom={18}
									scrollWheelZoom={false}>
									<TileLayer
										attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
										url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
									/>
									<Marker position={[state.item.lat, state.item.lon]} />
								</MapContainer>
							</Box>
						</>
					)}
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
});

export { Details };
