//@ts-nocheck

import {
	Box,
	Flex,
	Img,
	Text,
} from "@chakra-ui/react";
import { memo } from "react";

import moment from "moment";

type Props = {
	onClick: () => void;
	id: any;
	name: any;
	date: any;
	status: any;
	styles: any;
}

const Item: React.VFC<Props> = (
	{
		id,
		name,
		date,
		status,
		styles,
		onClick,
	}
) => {
	const isInput = status === 'input';

	return (
		<Flex
			key={id}
			className={styles["item"]}
			paddingLeft={{base: "20px", md: "60px"}}
			paddingRight={{base: "20px", md: "60px"}}
			alignItems="center"
			_hover={{backgroundColor: "custom.700"}}
			onClick={() => onClick(id)}
			cursor="pointer">
			<Box
				backgroundColor={isInput ? "#202c30" : "#311f1e"}
				borderRadius="10px"
				height={{base: '40px', md: '50px'}}
				width={{base: '40px', md: '50px'}}
				minWidth={{base: '40px', md: '50px'}}
				display="flex"
				justifyContent="center"
				alignItems="center">
				<Img
					margin="0 auto"
					src={isInput ? "./icone-seta-down.png" : "./icone-seta-up.png"}
					height={{base: '14px', md: '18px'}}
					width={{base: '14px', md: '18px'}}
				/>
			</Box>
			<Flex
				alignItems="center"
				justifyContent="space-between"
				wrap="wrap"
				marginLeft="25px"
				width="100%"
				height="100%"
				py="6"
				borderBottom="1px solid #28282A">
				<Text
					fontSize={{base: "16px", md: "20px"}}
					color="white"
					height="fit-content"
					width={{base: "100%", md: "fit-content"}}>
					{name}
				</Text>
				<Text
					fontSize={{base: "14px", md: "18px"}}
					height="fit-content"
					width={{base: "100%", md: "fit-content"}}>
					{moment(date).format('DD/MM/YYYY HH:mm')}
				</Text>
			</Flex>
		</Flex>
	)
};

export const PackageItem = memo(Item);
