import { History } from "./../screens/history";
import { MyData } from "./../screens/myData";
import { MyDataEdit } from "./../screens/myData/MyDataEdit";
import { MyDataPassword } from "./../screens/myData/MyDataPassword";
import { Realtime } from "./../screens/realtime";

// Auth
import { Login } from "./../screens/login";
import { ForgotPassword } from "./../screens/forgot";

// Erros
import Error404 from "./../screens/Error404";

// -----------------------------------------------------------------------------
// Routes
// -----------------------------------------------------------------------------
export const ROUTES = [
	{path: "/", component: History, logged: true},
	{path: "/mydata", component: MyData, logged: true},
	{path: "/mydata-changedata", component: MyDataEdit, logged: true},
	{path: "/mydata-changepassword", component: MyDataPassword, logged: true},
	{path: "/realtime", component: Realtime, logged: true},
	// Auth
	{path: "/login", component: Login, logged: false},
	{path: "/forgot", component: ForgotPassword, logged: false},
	// Erros
	{path: "/error/404", component: Error404},
];
