//@ts-nocheck

import {
	Grid,
	GridItem,
	Image,
	Text,
	Flex,
	Icon,
	Button,
	Circle,
} from "@chakra-ui/react";
import { TMGContainer } from "../../components/container";
import { Footer } from "../../components/Footer";
import { BasicHeader } from "../../components/BasicHeader";
import { LeftMenu } from "../../components/LeftMenu";
import { RiPencilFill } from "react-icons/ri";
import { Link as ReactRouterLink } from "react-router-dom";
import { useSelector } from "react-redux";

const MyData: React.VFC = () => {
	const userData = useSelector((state) => state.auth.userData);

	return (
		<>
			<LeftMenu />
			<Grid
				templateColumns="repeat(12, 1fr)"
				marginLeft={{base: 0, md: "140px"}}
			>
				<GridItem colSpan={12}>
					<BasicHeader title="MEUS DADOS" withLogout />
					<TMGContainer>
						<Flex gridGap={{base: "0", md: "10"}} wrap="wrap" pb={{base: "2", md: "0"}} mt={{base: "0", md: "4"}}>
							<Flex
								py="3"
								justifyContent={{base: "center", md: "flex-start"}}
								alignItems="center"
								width={{base: "100%", md: "unset"}}
							>
								<Circle size={{base: "130px", md: "170px"}} bg="#2c2c30" color="white">
									{userData.avatar ? (
										<Image
											position="absolute"
											borderRadius='full'
											boxSize={{base: '130px', md: '180px'}}
											src={userData.avatar}
											alt="Avatar"
										/>
									):(
										<>
											<Icon viewBox="0 0 88.8 92" width="18%" boxSize={{base: "10", md: "16"}}>
												<path
													fill="#9699a2"
													d="M44.4,46.8C31.5,46.8,21,36.3,21,23.4S31.5,0,44.4,0s23.4,10.5,23.4,23.4S57.3,46.8,44.4,46.8z M44.4,8
			                C35.9,8,29,14.9,29,23.4s6.9,15.4,15.4,15.4s15.4-6.9,15.4-15.4S52.9,8,44.4,8z"
												/>
												<path
													fill="#9699a2"
													d="M84.8,92c-2.2,0-4-1.8-4-4V74.4c0-2.8-2.3-5-5-5H13c-2.8,0-5,2.3-5,5V88c0,2.2-1.8,4-4,4s-4-1.8-4-4V74.4
			                    c0-7.2,5.8-13,13-13h62.7c7.2,0,13,5.8,13,13V88C88.8,90.2,87,92,84.8,92z"
												/>
											</Icon>
										</>
									)}
								</Circle>
							</Flex>
							<Flex pt={{base: "3", md: "0"}} pb={{base: "5", md: "0"}} width={{base: "100%", md: "unset"}} justifyContent="center">
								<Flex
									height="100%"
									direction="column"
									alignItems={{base: "center", md: "flex-start"}}
									justifyContent="center"
									color="white"
									fontWeight="300"
								>
									<Text fontWeight="bold" fontSize={{base: '24px', md: '28px'}}>
										{userData.name}
									</Text>
									<Text fontSize={{base: '16px', md: '18px'}}>{userData.unity_name}, {userData.unity_location}</Text>
									<Text fontSize={{base: '16px', md: '18px'}} mt="4">{userData.email}</Text>
									<Text fontSize={{base: '16px', md: '18px'}}>{userData.phone}</Text>
								</Flex>
							</Flex>
							<Flex py="0" pl={{base: "0", lg: "100px"}} width={{base: "100%", md: "unset"}} justifyContent="center">
								<Flex
									height="100%"
									direction="column"
									alignItems="flex-start"
									justifyContent="center"
								>
									<Button
										width={{base: "65%", md: "100%"}}
										minW="200px"
										mx={{base: "auto", md: "0"}}
										color="#81838b"
										rightIcon={<RiPencilFill />}
										variant="ghost"
										fontWeight="normal"
										px="12"
										py={{base: "6", md: "8"}}
										bg="transparent"
										fontSize={{base: "16px", md: "18px"}}
										_hover={{bg: 'white', color: 'black'}}
										_active={{bg: 'custom.500', color: '#81838b'}}
										as={ReactRouterLink}
										to="/mydata-changedata"
									>
										EDITAR CADASTRO
									</Button>
								</Flex>
							</Flex>
							<Flex py="3" width={{base: "100%", md: "unset"}} justifyContent="center">
								<Flex
									height="100%"
									direction="column"
									alignItems="flex-start"
									justifyContent="center"
								>
									<Button
										fontSize={{base: '16px', md: '18px'}}
										minW="200px"
										mx={{base: "auto", md: "0"}}
										width={{base: "65%", md: "100%"}}
										colorScheme="gray"
										bg="custom.500"
										color="white"
										fontWeight="normal"
										variant="solid"
										_hover={{bg: '#3e3e41'}}
										px="12"
										py={{base: "6", md: "8"}}
										as={ReactRouterLink}
										to="/mydata-changepassword"
									>
										ALTERAR SENHA
									</Button>
								</Flex>
							</Flex>
						</Flex>
					</TMGContainer>
					<Footer />
				</GridItem>
			</Grid>
		</>
	);
};

export { MyData };
