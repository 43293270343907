//@ts-nocheck

import {
	Alert,
	AlertIcon,
	AlertDescription,
	Button,
	Img,
	Link,
	Box,
	Text,
} from "@chakra-ui/react";
import { Footer } from "../../components/Footer";
import { TMGInput } from "../../components/Input";
import { Link as ReactRouterLink, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

import { authService } from "../../redux/services";

const ForgotPassword: React.VFC = () => {
	const [isSended, setIsSended]             = useState(false);
	const [email, setEmail]                   = useState("");
	const [error, setError]                   = useState("");
	const [showEmailError, setShowEmailError] = useState(false);
	const [isEmailValid, setIsEmailValid]     = useState(false);
	const [isLoading, setIsLoading]           = useState(false);
	const location                            = useLocation();

	function clearStates() {
		setEmail("");
		setShowEmailError(false);
		setIsEmailValid(false);
		setIsLoading(false);
	}

	useEffect(() => {
		clearStates();
	}, [location]);

	useEffect(() => {
		let user   = email.substring(0, email.indexOf("@"));
		let domain = email.substring(email.indexOf("@") + 1, email.length);

		if( (user.length >= 1) &&
			(domain.length >= 3) &&
			(user.search("@") === -1) &&
			(domain.search("@") === -1) &&
			(user.search(" ") === -1) &&
			(domain.search(" ") === -1) &&
			(domain.search(".") !== -1) &&
			(domain.indexOf(".") >= 1) &&
			(domain.lastIndexOf(".") < domain.length - 1)
		)
		{
			setIsEmailValid(true);
		}
		else
		{
			setIsEmailValid(false);
		}
	}, [email]);

	useEffect(() => {
		setShowEmailError(false);
	}, [isEmailValid]);

	function submitForm(e) {
		e.preventDefault();

		setIsLoading(true);

		if( !email )
		{
			setShowEmailError(true);
			setIsLoading(false);
		}
		else
		{
			setShowEmailError(false);

			const data = {
				email: email,
			};

			authService.passwordRecovery(data)
			.then((response) => {
				setIsSended(true);
				setIsLoading(false);
			})
			.catch((data) => {
				setIsLoading(false);
				setError(String(data));
			});
		}
	}

	return (
		<>
			<Box
				d="flex"
				margin={{base: '50px auto', md: '0 auto'}}
				height="90vh"
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
				px="20px"
				width="100%"
				maxWidth="580px"
			>
				<Img src="./logo.svg" width={{base: '140px', md: '200px'}} mb="35px" />
				<Box color="white" textAlign="center" mt="5">
					<Text fontSize={{base: '22px', md: '32px'}} mb="3" fontWeight="bold">
						RECUPERAR SENHA
					</Text>
					{isSended ? (
						<>
							<Text>Mensagem enviada com sucesso.</Text>
							<Text>Cheque seu e-mail para</Text>
							<Text>recuperar sua senha</Text>
						</>
					) : (
						<>
							<Text margin="0 auto" maxWidth={{base: '245px', md: '465px'}}>Digite abaixo o e-mail utilizado no cadastro e receba as instruções para recuperar sua senha.</Text>
						</>
					)}
				</Box>
				{isSended ? (
					<Button
						colorScheme="blue"
						size="xlg"
						bg="blue.200"
						width="100%"
						maxWidth="280px"
						mt="10"
						py="6"
						fontWeight="regular"
						_hover={{textDecoration: "none", bg: '#266987'}}
						as={ReactRouterLink}
						to="/login"
					>
						VOLTAR
					</Button>
				) : (
					<>
						{!!error && <Alert status='error' mt={5}>
							<AlertIcon />
							<AlertDescription>{error}</AlertDescription>
						</Alert>}
						<form onSubmit={submitForm} style={{width:"100%"}}>
							<TMGInput
								label="E-mail"
								name="email"
								type="email"
								marginTop="6"
								onChange={(e) => setEmail(e.target.value)}
								onFocus={() => setShowEmailError(false)}
								css={showEmailError && !email && `
								border-color: #ff4d4d;
								color: #ff4d4d;
								&::placeholder {
								color: #ff4d4d;
								}
							`}
							/>
							<Text
								display={showEmailError && !email ? "block" : "none"}
								textAlign="left"
								color="#ff4d4d"
								mt="2"
								width="100%"
							>
								Informe o e-mail
							</Text>
							<Text
								display={!isEmailValid && !showEmailError && email ? "block" : "none"}
								textAlign="left"
								color="#ff4d4d"
								mt="2"
								width="100%"
							>
								Informe um e-mail válido
							</Text>

							<Button
								type="submit"
								isLoading={isLoading}
								colorScheme="blue"
								disabled={isLoading || !isEmailValid}
								size="xlg"
								bg="blue.200"
								width="100%"
								mt="10"
								py="6"
								fontWeight="regular"
								_hover={{textDecoration: "none", bg: '#266987'}}
							>
								ENVIAR
							</Button>
						</form>
						<Link
							as={ReactRouterLink}
							to="/login"
							mt="10"
							color="#676870"
							fontSize={{base: "16px", md: "18px"}}
							_hover={{color: "white"}}
						>
							VOLTAR
						</Link>
					</>
				)}
			</Box>
			<Footer />
		</>
	);
};

export { ForgotPassword };
