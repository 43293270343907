//@ts-nocheck

import { Button, Img, Box, Text } from "@chakra-ui/react";
import { Footer } from "../../components/Footer";
import { Link as ReactRouterLink } from "react-router-dom";

const Error404: React.VFC = () => {
	return (
		<>
			<Box
				d="flex"
				margin="0 auto"
				height={{base: '85vh', md: '90vh'}}
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
				px="20px"
				width="100%"
				maxWidth="580px"
			>
				<Img src="./logo.svg" width={{base: '140px', md: '200px'}} mb="5" />
				<Text textAlign="center" color="#fff" mt="2">Página não encontrada!</Text>
				<Text textAlign="center" color="#fff" mt="10">O conteúdo que você solicitou não foi encontrado em nossos servidores.</Text>
				<Button
					fontSize={{base: '16px', md: '18px'}}
					minW="200px"
					mx={{base: "auto", md: "0"}}
					mt={20}
					width={{base: "65%", md: "100%"}}
					colorScheme="gray"
					bg="custom.500"
					color="white"
					fontWeight="normal"
					variant="solid"
					_hover={{bg: '#3e3e41'}}
					px="12"
					py={{base: "6", md: "8"}}
					as={ReactRouterLink}
					to="/"
				>
					VOLTAR PARA O INÍCIO
				</Button>
			</Box>
			<Footer />
		</>
	);
};

export default Error404;
