//@ts-nocheck

import Icon from "@chakra-ui/icon";
import { Box, Center, Text } from "@chakra-ui/layout";
import { useLocation } from "react-router";

const MyDataBox: React.VFC = () => {
  const location = useLocation();
  const color = location.pathname.match("/mydata") ? "#444444" : "gray.50";
  return (
    <Box
      w="100%"
      height="50px"
      textAlign="center"
      style={{ marginTop: "80px" }}
    >
      <Center>
        <Icon viewBox="0 0 70 70" color={color} width="18%" boxSize={8}>
          <path
            fill="currentColor"
            d="M1009.5,439.5A14.5,14.5,0,1,0,995,425,14.51,14.51,0,0,0,1009.5,439.5Zm0-24a9.5,9.5,0,1,1-9.5,9.5A9.51,9.51,0,0,1,1009.5,415.5Z"
            transform="translate(-982 -410.5)"
          />
          <path
            fill="currentColor"
            d="M1028.91,448.5H990.09a8.1,8.1,0,0,0-8.09,8.09V465a2.5,2.5,0,0,0,5,0v-8.41a3.1,3.1,0,0,1,3.09-3.09h38.82a3.1,3.1,0,0,1,3.09,3.09V465a2.5,2.5,0,0,0,5,0v-8.41A8.1,8.1,0,0,0,1028.91,448.5Z"
            transform="translate(-982 -410.5)"
          />
        </Icon>
      </Center>
      <Text mt="2" fontSize="14" color={color}>
        MEUS DADOS
      </Text>
    </Box>
  );
};

export { MyDataBox };
