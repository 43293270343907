//@ts-nocheck

import {
	Grid,
	GridItem,
	Flex,
	Text,
	Box,
} from "@chakra-ui/react";
import { useState, useEffect, useRef } from "react";
import { Footer } from "../../components/Footer";
import { LeftMenu } from "../../components/LeftMenu";
import { PackageItem } from "../../components/PackageItem";
import styles from "./styles.module.scss";
import { Details } from "../history/Details";
import { RealtimeHeader } from "../../components/RealtimeHeader";
import { useSelector } from "react-redux";
import moment from "moment";

const Realtime: React.VFC = () => {
	const userData = useSelector((state) => state.auth.userData);

	const [state, setState] = useState({
		data           : [],
		lastHistoryDate: null,
	});

	const channel = `unity.${userData.unity_id}`;

	const _showScreen  = useRef(null);
	const _firstRender = useRef(true);

	useEffect(() => {
		_firstRender.current = false;

		Echo.private(channel)
		.listen('.new.package', (e) => {
			setState(prevState => ({
				...prevState,
				data           : [
					...prevState.data,
					{
						...e.package,
						listKey: `${e.package.uuid}-${Math.floor(Math.random() * 100000)}`,
					},
				],
				lastHistoryDate: e.package.last_history_date,
			}));
		});

		return function cleanup() {
			Echo.leaveChannel(channel);
		};
	}, []);

	function onItemClick(id) {
		_showScreen.current.open(id);
	}

	return (
		<>
			<LeftMenu />
			<Grid templateColumns="repeat(12, 1fr)">
				<Details ref={_showScreen} />
				<GridItem colSpan={12} marginLeft={{base: 0, md: "140px"}}>
					<RealtimeHeader title={`${state.data.length} PACOTE${state.data.length === 1 ? '' : 'S'}`} />
					<Box
						position="relative"
						pt="8"
						pb="12"
						width="100%"
						minH={{
							base: "calc(100vh - 477px)",
							md  : "calc(100vh - 224px)",
						}}
						bgGradient="linear(#18181A, gray.300)">
						<Box>
							<Flex justifyContent="space-between" mb={{base: '2', md: '6'}} px={{base: '20px', md: '60px'}}>
								<Flex w="100%">
									<Text color="#676870" fontSize={{base: "14px", md: "18px"}}>
										ÚLTIMA LEITURA REALIZADA: {state.lastHistoryDate ? moment(state.lastHistoryDate).format('DD/MM/YYYY HH:mm') : '-'}
									</Text>
								</Flex>
							</Flex>
							<div>
								{state.data.map((item) => (
									<PackageItem
										key={item.listKey}
										onClick={onItemClick}
										id={item.uuid}
										name={item.name}
										date={item.last_history_date}
										status={item.status}
										styles={styles}
									/>
								))}
							</div>
						</Box>
					</Box>
					<Footer />
				</GridItem>
			</Grid>
		</>
	);
};

export { Realtime };
