//@ts-nocheck

import { CloseButton } from "@chakra-ui/close-button";
import { Flex, Spacer } from "@chakra-ui/layout";
import { Dispatch, SetStateAction } from "react";
import moment from "moment";

type Filter = {
	start: any;
	end: any;
	bag: any;
	in: boolean;
	out: boolean;
};

type Props = {
	filter: Filter;
	setFilter: Dispatch<SetStateAction<Filter>>;
	setIsApplied: Dispatch<SetStateAction<boolean>>;
};

const FilteredItems: React.VFC<Props> = ({filter, setFilter, setIsApplied}) => {
	const removeFilter = (field: string, value: string|boolean|number) => {
		const newFilter = field === "dates" ? {...filter, start: "", end: ""} : {...filter, [field]: value};

		if( !newFilter.start && !newFilter.end && !newFilter.bag && !newFilter.in && !newFilter.out )
		{
			setIsApplied(false);
		}

		setFilter(newFilter);
	};

	return (
		<>
			{filter.start && filter.end && (
				<Flex
					bg="gray.900"
					color="white"
					px="4"
					py="3"
					width="fit-content"
					borderRadius="8"
					whiteSpace="nowrap"
					css={`
						flex-grow:1;
					`}>
					{moment(filter.start).format("DD/MM/YYYY")} -{" "}
					{moment(filter.end).format("DD/MM/YYYY")}
					<Spacer />
					<CloseButton
						size="sm"
						ml="4"
						onClick={() => removeFilter("dates", "")}
					/>
				</Flex>
			)}
			{filter.bag && (
				<Flex
					bg="gray.900"
					color="white"
					px="4"
					py="3"
					width="fit-content"
					borderRadius="8"
					whiteSpace="nowrap">
					Bag {filter.bag}
					<Spacer />
					<CloseButton
						size="sm"
						ml="4"
						onClick={() => removeFilter("bag", "")}
					/>
				</Flex>
			)}
			{filter.in && (
				<Flex
					bg="gray.900"
					color="white"
					px="4"
					py="3"
					width="fit-content"
					borderRadius="8"
					whiteSpace="nowrap">
					Entrada
					<Spacer />
					<CloseButton
						size="sm"
						ml="4"
						onClick={() => removeFilter("in", false)}
					/>
				</Flex>
			)}
			{filter.out && (
				<Flex
					bg="gray.900"
					color="white"
					px="4"
					py="3"
					width="fit-content"
					borderRadius="8"
					whiteSpace="nowrap">
					Saída
					<Spacer />
					<CloseButton
						size="sm"
						ml="4"
						onClick={() => removeFilter("out", false)}
					/>
				</Flex>
			)}
		</>
	);
};

export { FilteredItems };
